//export const baseURL = "http://localhost:8080/family/index.php/";
export const baseURL = "https://famile.org/be/index.php/";
export const signup = baseURL + "cust/signup";
export const updatePhoneSignup = baseURL + "cust/updatePhoneSignup";
export const updateGeneric = baseURL + "cust/updateGeneric";
export const verifyVerificationNumber =
  baseURL + "cust/verifyVerificationNumber";
export const resendVerificationNumber =
  baseURL + "cust/resend_verification_number";
export const createPass = baseURL + "cust/createPass";
export const paymentInitiate = baseURL + "pi/paymentInitiate";
export const login = baseURL + "cust/login_acc";
export const setpersonalinfo = baseURL + "cust/setpersonalinfo";
export const getpersonalinfo = baseURL + "cust/getpersonalinfo";
export const setPreferance = baseURL + "cust/setpreferance";
export const getPreferance = baseURL + "cust/getpreferance";
export const getSubscription = baseURL + "cust/getSubscription";
export const emailExists = baseURL + "cust/email_exists";
export const phoneExists = baseURL + "cust/phone_exists";
export const getSenderDataFromURLID = baseURL + "cust/getSenderDataFromURLID";
export const getReceiverDataFromURLID =
  baseURL + "cust/getReceiverDataFromURLID";
export const getSenderProfileConfirmationFromURLID =
  baseURL + "cust/getSenderProfileConfirmationFromURLID";
export const getConfirmMutualInterestFromURLID =
  baseURL + "cust/getConfirmMutualInterestFromURLID";
export const forgetPasswordURL = baseURL + "cust/forgot_pass_1";
export const resetPasswordURL = baseURL + "cust/forgot_pass_3";
export const languagelist = baseURL + "cust/lang_list";
export const religionlist = baseURL + "cust/religion_list";
export const incomelist = baseURL + "cust/income_list";
export const statelist = baseURL + "cust/state_list";
export const matchProfLand = baseURL + "cust/matchProfLand";
export const matchProfView = baseURL + "cust/matchProfView";
export const matchProfConf = baseURL + "cust/matchProfConf";
export const intProfLand = baseURL + "cust/intProfLand";
export const intProfView = baseURL + "cust/intProfView";
export const intProfConf = baseURL + "cust/intProfConf";

