import React, { useEffect, useState } from "react";
import "../css/common.css";
import "../css/profile1.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { matchProfLand } from "../constant/url";

export const ProfileStep1 = (props) => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [visitedCount, setVisitedCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const bodyFormData = new FormData();
      bodyFormData.append("verify_match_id", id);
      axios({
        method: "post",
        url: matchProfLand,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            if (res_data.sender_accepted === "1" && res_data.receiver_accepted === "1") {
              navigate(`/Receiver_Sender_Confirmation/${id}`, {replace: true});
              return;
            }
            if (res_data.sender_accepted === "1") {
              navigate(`/matchProfView/${id}`, {replace: true});
              return;
            }
            const profData = res_data.match_prof;
            
            if (!profData.blur_photo.startsWith("https://famile.org/be/upload/blur_img/")) {
              profData.blur_photo = `https://famile.org/be/upload/blur_img/${profData.blur_photo}`
            }
            if (!profData.photo.startsWith("https://famile.org/be/upload/customer_img/")) {
              profData.photo = `https://famile.org/be/upload/customer_img/${profData.photo}`
            }
            setVisitedCount(+res_data.sender_visited);
            setProfile(profData);
          }
          // NOTE: Uncomment only for testing
          // setVisitedCount(1);
          // setProfile({
          //   fname: "Some dummy",
          //   age: -20,
          //   city: "Alien",
          //   photo: require("../assets/img/signup/profileImg.jpg"),
          // });
          // NOTE: Uncomment only for testing
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }, [id]);

  if (!profile) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header loginStatus={props.loginStatus} />
      <div className="customProfileBg">
        <div className="container d-flex justify-content-center">
          <div className="main-div custom_Profile1">
            <div className="content-div custom_bg customLin">
              <div className="row">
                <div className="col d-flex ">
                  <div className="custom_Profile">
                    <div className="custom_textSection">
                      <div className="Custom_Img">
                        <img src={profile.blur_photo} alt="profile"  />
                      </div>

                      <p className="text-center ">
                        {profile.fname}, {profile.age}, {profile.city}
                      </p>

                      <h2>MATCHING PROFILE</h2>

                      <h3>
                        You can view their profile upto 3 times before you
                        Express Interest. If you Express Interest, your profile
                        will be Immediately shared with them.
                      </h3>

                      <div className="ProfileSteps">
                        <div className="stepper-wrapper">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <div
                              key={index + "unique"}
                              className={
                                "stepper-item " +
                                (index < visitedCount ? "completed" : "")
                              }
                            >
                              <div
                                className="step-counter"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    visitedCount > 2 ? "&#10003;" : index + 1,
                                }}
                              />
                              <div className="step-name">
                                View{index < visitedCount ? "ed" : ""}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {visitedCount < 3 ? (
                        <div className="agreeBtn">
                          <button
                            onClick={() => {
                              navigate(`/matchProfView/${id}`);
                            }}
                          >
                            AGREE & VIEW
                          </button>
                        </div>
                      ) : (
                        <h4>
                          You have exhausted the number of Views on this profile
                          without Expressing Your Interest
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="col d-flex justify-content-start ">
                  <img
                    className="img-fluid btm-flower-left"
                    width="160px"
                    height="160px"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img
                    className="img-fluid btm-flower-right"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ft-footer-block">
        <Footer />
      </div>
    </>
  );
};
