import React from "react";
import "../css/common.css";
import "../css/profile1.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";

export const ProfileStep3 = (props) => {
    return (
        <>
            <Header loginStatus={props.loginStatus} />
            <div className="customProfileBg">
                <div className="container d-flex justify-content-center">
                    <div className="main-div custom_Profile1">
                        <div className="content-div custom_bg">
                            <div className="row">
                                <div className="col d-flex">
                                    <div className="custom_Profile">
                                        <div className="custom_textSection">
                                            <div className="Custom_Img">
                                                <img
                                                    src={require("../assets/img/signup/coomon.png")}
                                                    alt="profile"
                                                />
                                            </div>

                                            <h2 style={{marginTop: "50px"}}>Confirmed Interest</h2>

                                            <h3>
                                                You both have confirmed your mutual interest in each other
                                                Your respective contact details have been shared with each other on SMS & Email
                                                Please feel free to initiate communication with this person by sending a friendly message
                                                Kindly ensure all communication is respectful of each others boundaries
                                            </h3>
                                          
                                                <div className="tickMarkBlack">
                            <img
                              src={require("../assets/img/signup/tickCircle.png")}
                              alt="tick"
                            />
                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="col d-flex justify-content-start ">
                                    <img
                                        className="img-fluid btm-flower-left"
                                        width="160px"
                                        height="160px"
                                        src={require("../assets/img/signup/btmflower2.png")}
                                        alt="profileimg"
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="col d-flex justify-content-end">
                                    <img
                                        className="img-fluid btm-flower-right"
                                        src={require("../assets/img/signup/btmflower2.png")}
                                        alt="profileimg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ft-footer-block">
                <Footer />
            </div>
        </>
    );
};
