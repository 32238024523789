import React from "react";
// import "../css/common.css";
import "../css/profile2.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";

export const ProfileStep4 = (props) => {
  return (
    <>
      <Header loginStatus={props.loginStatus} />
      <div className="customProfileBg2">
        <div className="container d-flex justify-content-center ">
          <div className="main-div custom_Profile1">
            <h1>Confirm my Interest</h1>
            <div className="content-div custom_bg">
              <div className="row customBox">
                <div className="col d-flex">
                  <div className="custom_Profile">
                    <div className="custom_textSection">
                      <div className="Box_preview">
                        <div className="previewImage">
                          <img
                            src={require("../assets/img/signup/preview.png")}
                            alt="profile"
                          />
                        </div>
                        <div className="preview_text">
                          <p>KayalVizhi</p>
                          <p>27, Female</p>
                          <p>Chennai, Tamil Nadu</p>
                          <p>Software Engineer </p>
                          <p>Rs.1,00,000 Per Month</p>
                          <p>80 kgs, 180 cms</p>
                          <p>Non-Vegetarian, Christian</p>
                          <p>Smoking (Never)</p>
                          <p>Drinking (Occasional)</p>
                        </div>
                      </div>

                      {/* Matching Profile */}
                      <div>
                        <div className="preview_descp">
                          By clicking below, you are consenting to your profile
                          being sent to this person. If they are mutually
                          interested in you, you will receive access to their
                          phone number and vice versa.
                        </div>
                        <div className="agreeBtn">
                          <button>Confirm my Interest</button>
                        </div>
                      </div>

                      {/* After Success */}
                      {/* <div>
                                                <div className="tickMark">
                                                    <img src={require("../assets/img/signup/tickCircle.png")} alt="tick" />
                                                </div>
                                                <p className="successText">Your interest has been sent to this person. Please wait while they confirm their interest.</p>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="col d-flex justify-content-start ">
                  <img
                    className="img-fluid btm-flower-left"
                    width="160px"
                    height="160px"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img
                    className="img-fluid btm-flower-right"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ft-footer-block">
        <Footer />
      </div>
    </>
  );
};
