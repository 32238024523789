import React, { useEffect, useState } from "react";
import {
  languagelist,
  religionlist,
  setPreferance,
  statelist,
} from "../constant/url";
import "../css/newStyle.css";
import axios from "axios";

export const PreferanceView = (props) => {
  const [formData, setFormData] = useState({
    gender: "",
    fromAge: "",
    toAge: "",
    marital_status: "",
    food: "",
    language: "",
    religion: "",
    state: "",
    country: "",
  });
  const isValidUser = sessionStorage.getItem("access_detals");
  const [languages, setLanguages] = useState([]);
  const [religions, setReligions] = useState([]);
  // const [incomes, setIncomes] = useState([]);
  const [states, setStates] = useState([]);
  useEffect(() => {
    setFormData(props.formData);
  }, [props.formData]);

  useEffect(() => {
    props.handlePreferance();
  }, []);

  useEffect(() => {
    if (isValidUser) {
      axios({
        method: "post",
        url: languagelist,
        data: {
          authId: isValidUser,
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   lang_id: number;
            //   lang_name: String;
            // }
            setLanguages(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch languages", response);
        });

      axios({
        method: "post",
        url: religionlist,
        data: {
          authId: isValidUser,
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   relgs_id: number;
            //   relgs_name: String;
            // }
            setReligions(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch religions", response);
        });

      // axios({
      //   method: "post",
      //   url: incomelist,
      //   data: {
      //     authId: isValidUser,
      //   },
      //   headers: { "Content-Type": "multipart/form-data" },
      // })
      //   .then(function (response) {
      //     //handle success
      //     const res_data = response.data;
      //     if (res_data.status_code === 200) {
      //       // {
      //       //   incom_id: number;
      //       //   incom_name: String;
      //       // }
      //       setIncomes(res_data.data);
      //     }
      //   })
      //   .catch(function (response) {
      //     //handle error
      //     console.log("Unable to fetch incomes", response);
      //   });

      axios({
        method: "post",
        url: statelist,
        data: {
          authId: isValidUser,
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   stat_id: number;
            //   stat_name: String;
            // }
            setStates(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch states", response);
        });
    }
  }, [isValidUser]);
  return (
    <div className="preftbl-pref row">
      <div className="d-flex justify-content-center">
        <div className="col-lg-5 col-md-7 col-sm-9 col-11">
          <div className="col-12 mb-3">
            <div class="fieldInput genderField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={formData.gender}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="d-flex justify-content-between gap-2">
              <div className="preferencePgInput fieldInput fieldAge">
                <input
                  className="h-48 "
                  style={{ width: "100%" }}
                  type="text"
                  value={formData.fromAge}
                  disabled
                />
              </div>
              <div className="preferencePgInput fieldInput fieldAge">
                <input
                  className="h-48 fieldAge"
                  style={{ width: "100%" }}
                  type="text"
                  value={formData.toAge}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput maritalField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={formData.marital_status}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput foodField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={formData.food}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput langField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={
                  formData.language === "9999"
                    ? "Any"
                    : languages.find(
                        (item) => item.lang_id === formData.language
                      )?.lang_name
                }
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput relegionField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={
                  formData.religion === "9999"
                    ? "Any"
                    : religions.find(
                        (item) => item.relgs_id === formData.religion
                      )?.relgs_name
                }
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput smokeField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={formData.smoke}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput drinkField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={formData.drink}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <div class="fieldInput locField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={
                  formData.state === "9999"
                    ? "Any"
                    : states.find((item) => item.stat_id === formData.state)
                        ?.stat_name
                }
                disabled
              />
            </div>
          </div>
          <div className="col-12">
            <div class="fieldInput countryField preferencePgInput">
              <input
                className="h-48 col-12"
                type="text"
                value={formData.country}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3 mt-40 d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-primary bg-dark p-2 col-7 otpBtt"
              onClick={() => props.pageChange("EDIT")}
            >
              Edit Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
