import React from "react";
import "../css/common.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { useEffect } from "react";
import { useState } from "react";

export const TermsOfUse = (props) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return (
		<>
			<Header loginStatus={props.loginStatus} />
			<img className="backBg" src={require('../assets/img/signup/bannerT.jpg')} alt="" />
			<div className="customSectTerm">
				<div
					className="container d-flex justify-content-center "
					style={{ position: "relative", top: "55px" }}
				>
					<div className="main-div">
						<div className="">
							<div className="row customTerm">
								<h2
									className={
										windowWidth <= 768 ? "text-center" : "text-center mt-5"
									}
								>
									Terms of Use
								</h2>
								<div className="col d-flex justify-content-center">
									<div className="col-12 col-md-10 px-5 tos-content">
										<br />
										<p style={{ paddingBottom: "unset" }} className="para_repHead">
											<b>INTRODUCTION</b>
										</p>
										<p className="para_rep">
											Famile.org is solely owned and operated by 1D1S Entercon Pvt
											Ltd, Chennai, India.
											<br></br>
											By using our services, you agree to comply with and be bound
											by these terms and conditions.
										</p>
										<p className="para_repHead">
											<b>ELIGIBILITY</b>
										</p>
										<p className="para_rep">
											You must be at least 18 years old to register on our site.
											<br></br>
											By registering, you confirm that you are legally capable of
											entering into a matrimonial alliance.
											<br></br>
											Since our website specifically mentions that we do support
											people who do not believe in caste, by subscribing to the
											services of our website, you hereby confirm that you are a
											person who does not believe in caste, and any infringement
											on this ideology will be taken very seriously and your
											account will be banned immediately.
											<br></br>
											Famile.org reserves the right to forthwith terminate your
											use and/or registration without refund of any subscription
											fee paid, if at any time Famile.org is, in its sole
											discretion, of the opinion or has any reason to believe that
											you are not eligible to use this Website or that you have
											made any misrepresentation about your eligibility.
										</p>
										<p className="para_repHead">
											<b>REGISTRATION AND PROFILE INFORMATION</b>
										</p>
										<p className="para_rep">
											You agree to provide accurate and current information during
											the registration process.
											<br></br>
											Profiles found to contain false information may be subject
											to removal without notice.
											<br></br>
											You hereby represent and warrant that you are representing
											yourself or the person you are creating a profile for is
											your nearest member of family (such as son, daughter,
											brother, sister, etc.,) and you are doing so with their full
											knowledge and consent. By creating a profile and subscribing
											to our services, you agree to represent only authentic data
											and nothing is falsified.
											<br></br>
											As part of the registration process, you are required to
											setup your preferences. If you do not set your preferred
											matching preferences, our algorithms will take into default
											cognizance, the best matching criteria setup on similar
											profiles as yours.
											<br></br>
											Upon registration and payment of the annual subscription
											fees, Famile.org will start sending you matches, which will
											be based on availability of matching profiles.
										</p>
										<p className="para_repHead">
											<b>REFUND POLICY</b>
										</p>
										<p className="para_rep">
											After your profile registration is completed, your profile
											will be shared with other matching users, and you will be
											receiving matching profile as well. Once this process
											starts, we will not be able to issue refunds.
											<br></br>
											In special cases, where users have not received any profile
											matches at all during the course of their annual
											subscription, users may contact us, and our team may
											consider extending the duration of the subscription, or
											adding a new subscription either totally free of cost or at
											a reduced price. However, Famile.org reserves the right to
											do so and it will be at our sole discretion.
										</p>
										<p className="para_repHead">
											<b>PRIVACY AND SECURITY</b>
										</p>
										<p className="para_rep">
											We prioritize the privacy and security of your personal
											information. Please refer to our Privacy Policy for details.
											<br></br>
											You are responsible for maintaining the confidentiality of
											your account credentials.
										</p>
										<p className="para_repHead">
											<b>USER CONDUCT</b>
										</p>
										<p className="para_rep">
											Users must conduct themselves in a respectful and lawful
											manner.
											<br></br>
											Harassment, abuse, or any form of misconduct towards other
											users is strictly prohibited.
										</p>
										<p className="para_repHead">
											<b>CONTENT GUIDELINES</b>
										</p>
										<p className="para_rep">
											Users are responsible for the content they post on the site,
											including text, images, and other media.
											<br></br>
											Prohibited content includes but is not limited to offensive,
											misleading, or illegal material.
										</p>

										<p className="para_repHead">
											<b>INTERACTION WITH OTHER USERS</b>
										</p>
										<p className="para_rep">
											Famile.org is not responsible for the outcome of
											interactions between users.
											<br></br>
											Users are encouraged to exercise caution and conduct
											thorough verification before proceeding with matrimonial
											alliances.
											<br></br>
											You further understand and acknowledge that while
											interacting with other users, you may be exposed to content
											that is inaccurate, offensive, indecent, or objectionable,
											and you hereby waive any legal or equitable rights or
											remedies you have or may have against Famile.org with
											respect thereto and agree to indemnify and hold the
											Famile.org harmless to the fullest extent allowed by law
											regarding all matters related to your use of the services
											provided on Famile.org or any of our subsidiary or related
											service associated with us.
											<br></br>
											Users who find suitable profiles of partners through the
											Website must independently verify/confirm details of the
											potential partners and should conduct due diligence on their
											own. Famile.org will not be responsible for the authenticity
											or correctness of any information exchanged through the
											Website.
											<br></br>
											By using our services, you agree to behave responsibly while
											interacting with other users and will not exhibit indecent
											behaviour or solicit other users for financial favours,
											physical relationships, harass other users in any form or
											engage in inappropriate communication language. You hereby
											understand that violation of this policy will result in
											immediate termination of your account and you will not be
											issued any refund of your subscription fees.
											<br></br>
											You agree not to stalk or persist communication with a
											person may have communicated with you through Famile.org but
											has lost interest in you after communications. Such
											behaviour will be deemed as violation of privacy and
											harassment and your profile will be terminated and no
											refunds will be made. We will cooperate with law-enforcement
											authorities depending on the seriousness and gravity of your
											violation.
											<br></br>
											You agree not to publish or transmit any data related to a
											profile which was communicated to you via Famile.org, to
											anyone else.
										</p>
										<p className="para_repHead">
											<b>TERMINATION OF ACCOUNT</b>
										</p>
										<p className="para_rep">
											Famile.org reserves the right to suspend or terminate
											accounts that violate these terms.
											<br></br>
											Users may terminate their accounts at any time after
											notifying us.
											<br></br>
											Users who are getting married are required to notify us so
											our website algorithms stop connecting users with your
											profile.
											<br></br>
											Use or launch any automated system, including without
											limitation, "robots", "spiders", "offline readers", etc.,
											that accesses the Website Famile.org in a manner that sends
											more request messages to Website servers in a given period
											of time than a human can reasonably produce in the same
											period by using a conventional online web browser shall
											result in termination of your account.
											<br></br>
											You agree not to collect or harvest any personally
											identifiable information, including account names, from
											Famile.org, nor to use the communication systems provided by
											the Website for any commercial solicitation purposes.
										</p>
										<p className="para_repHead">
											<b>MODIFICATION OF TERMS</b>
										</p>
										<p className="para_rep">
											These terms may be modified at any time without prior
											notice. Users are encouraged to review the terms regularly.
										</p>
										<p className="para_repHead">
											<b>GOVERNING LAW</b>
										</p>
										<p className="para_rep">
											These terms are governed by the laws of Chennai, Tamil Nadu,
											India.
											<br></br>
											Any disputes arising out of or relating to these terms will
											be subject to the exclusive jurisdiction of the courts in
											Chennai, Tamil Nadu, India.
										</p>
										<p className="para_repHead">
											<b>CONTENTt</b>
										</p>
										<p className="para_rep">
											Under no circumstances will Famile.org be liable in any way
											for any Content, including, but not limited to, any errors
											or omissions in any Content, or any loss or damage of any
											kind incurred as a result of the use of any Content made
											available through the Website or as a result thereof.
											<br></br>
											You understand and agree that when using the Website, you
											will be exposed to Content from different sources and that
											Famile.org shall not be responsible for the accuracy,
											usefulness, safety, or intellectual property rights of or
											relating to such Content.
										</p>
										<p className="para_repHead">
											<b>COMMUNICATION WITH US</b>
										</p>
										<p className="para_rep">
											If any content on the Website does not adhere to these terms
											and conditions, please send an email to hello@famile.org
											indicating the non-adherence so as to enable Famile.org to
											take appropriate action as deemed necessary by Famile.org.
											<br></br>
											The user has to inform Famile.org that the User has married
											another User of the Website and wishes to update the Website
											with a success story. Famile.org reserves the right to
											request such documents as proof of a marriage as it may deem
											fit from the User or both the Users.
										</p>
										<p className="para_repHead">
											<b>OUR MATCHING ALGORITHMS</b>
										</p>
										<p className="para_rep">
											Our matching or match-making algorithms are engineered to
											send you up to 3 of the best matching profiles based on your
											preferences and your profile information.
											<br></br>
											Our matching algorithms do not guarantee sending you matches
											every week, and will be able to send you profiles only based
											on availability of matching profiles.
										</p>
										<p className="para_repHead">
											<b>MARKETING</b>
										</p>
										<p className="para_rep">
											Famile.org also reserves the right to publish the success
											story of users at its discretion.
										</p>
										<p className="para_repHead">
											<b>OUR SOFTWAR & SERVICES</b>
										</p>
										<p className="para_rep">
											Reverse engineer, decompile, disassemble, copy, reproduce,
											distribute, modify, transmit, perform, publish or create
											derivative works from or in any way exploit any part of the
											Website in violation of these Terms or the laws of any
											country.
											<br></br>
											Create a database in electronic or structured manual form by
											systematically downloading and storing the entire Website or
											part thereof except such information as relates or concerns
											you directly.
										</p>
										<p className="para_repHead">
											<b>YOUR RESPONSIBILITIES</b>
										</p>
										<p className="para_rep">
											You are requested to keep your login credentials private and
											not to disclose it to anyone. Famile.org is not responsible
											for any misuse of your account that may arise out of you
											disclosing your account information to anyone else.
											<br></br>
											Keep your information appropriate and updated at all times.
											<br></br>
											Famile.org is not responsible for any issues or conflicts
											that may arise out of your misrepresentation of your data.
										</p>
									</div>
								</div>
							</div>
							<div className="d-none d-md-block">
								<div className="row ">
									<div className="col">
										<div className=" d-flex justify-content-start">
											<img
												className="img-fluid btm-flower-left btm-flower-leftCustom"
												src={require("../assets/img/signup/btmflower2.png")}
												alt="profileimg"
											/>
										</div>
									</div>
									<div className="col">
										<div className=" d-flex justify-content-end ">
											<img
												className="img-fluid btm-flower-right btm-flower-rightCustom"
												src={require("../assets/img/signup/btmflower2.png")}
												alt="profileimg"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="ft-footer-block">
				<Footer />
			</div>
		</>
	);
};
