import React, { useEffect } from "react";
import { Header } from "../component/Header";
import { Footer } from "../component/Footer";
import { useParams } from "react-router-dom";
import Verify from "../component/Verify";
import "../css/newStyle.css";


function VerificationCode() {
  const { code, phone } = useParams();
  useEffect(() => {
    const disableBackButton = (e) => {
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = function(event) {
        window.scrollTo(-100, 0);
        window.history.pushState(null, document.title, window.location.href);
      };
    };

    disableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <>
      <Header ret_type={(e) => {}} acc_type={"login"} />
      <div className="LOGIN">
        <div className="container">
          <div className="row">
            <Verify code={code} phone={phone}/>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0">
              <div className="login-block-right d-flex justify-content-end resp_marg">
                <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                  <div>
                    {/* <img
                      className="vec-heart img-fluid customHeart"
                      alt="Famile mm"
                      src={require("../assets/img/signup/Vector.png")}
                    /> */}
                    <div className="col custom_col">
                    <img
                        className="img4 img-h-full img-fluid  custom_img_banner"
                        alt="Famile mm"
                        src={require("../assets/img/signup/banner_img.png")}
                      />
                      {/* <div className="">
                        <div className="d-flex justify-content-center custom_space">
                          <div className="col-5 d-flex justify-content-center ">
                            <img
                              className="img1  img-fluid p-2 pb-3 customImgHeight1"
                              alt="Famile mm"
                              src={require("../assets/img/signup/1_15_11zon.png")}
                            />
                          </div>
                          <div className="col-7 d-flex justify-content-center ">
                            <img
                              className="img4  img-fluid p-2 pb-3 customImgHeight2"
                              alt="Famile mm"
                              src={require("../assets/img/signup/2_16_11zon.png")}
                            />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="">
                        <div className="d-flex justify-content-center">
                          <div className="col-7 d-flex justify-content-center">
                            <img
                              className="img2 img-w-h-full img-fluid p-2 pt-0 customImgHeight3"
                              alt="Famile mm"
                              src={require("../assets/img/signup/3_17_11zon.png")}
                            />
                          </div>
                          <div className="col-5">
                            <img
                              className="img3 img-fluid img-w-h-full p-2 pt-0 customImgHeight4"
                              alt="Hands indian bride"
                              src={require("../assets/img/signup/4_18_11zon.png")}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block">
        <div className="d-flex d-md-none justify-content-between mx-lg-3">
          <img
            className="img-fluid"
            src={require("../assets/img/signup/left-tree.png")}
            alt="left tree"
          />
          <img
            className="img-fluid"
            src={require("../assets/img/signup/right-tree.png")}
            alt="left tree"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default VerificationCode;
