import React, { lazy, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ScrollToTop from "./component/ScrollToTop";

import { Login } from "./pages/Login";
import { PersonalInfoPage } from "./pages/PersonalInfoPage";
import { PaymentResult } from "./pages/PaymentResult";
import { Subscription } from "./pages/Subscription";
import { TermsOfService } from "./pages/TermsOfService";
import { TermsOfUse } from "./pages/TermsOfUse";
import { Preference } from "./pages/Preference";
import { Privacy } from "./pages/Privacy";
import { AccessContext } from "./constant/AccessContext";
import { SubscriptionRate } from "./pages/SubscriptionRate";
import { PaymentFailed } from "./pages/PaymentFailed";
import { SgPersonalInfo } from "./pages/SgPersonalInfo";
import { SgPersonalInfoImage } from "./pages/SgPersonalInfoImage";
import { EmailProfileConfirmation } from "./pages/EmailProfileConfirmation";
import { EmailProfileConfirmedResponse } from "./pages/EmailProfileConfirmedResponse";
import { SignupTermsOfUse } from "./pages/SignupTermsOfUse";
import VerificationCode from "./pages/VerificationCode";
import SignUp from "./pages/SignUp";
import { ProfileStep4 } from "./pages/matchProfConf";
import { ProfileStep1 } from "./pages/matchProfLand";
import { ProfileStep2 } from "./pages/matchProView";
import { ProfileStep3 } from "./pages/Receiver_Sender_Confirmation";
import { ProfileStep5 } from "./pages/introProfConf";
import { ProfileStep6 } from "./pages/intProView";
import { ProfileStep7 } from "./pages/intProfLand";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const pathname = queryParameters.get("path");
  const aid = queryParameters.get("aid");
  const phone = queryParameters.get("phone");
  const uqURL = queryParameters.get("uqid");

  const navigate = useNavigate();
  const location = useLocation();

  const [authID, setAuthID] = useState();
  const [loginStatus, setLoginStatus] = useState("NOTLOGGEDIN");

  const handleAuthID = (ret_item) => {
    if (ret_item !== undefined && ret_item !== "") {
      sessionStorage.setItem("access_detals", ret_item.authID);
      sessionStorage.setItem("phone", ret_item.phone);
      setAuthID(ret_item.authID);
    }
  };

  const handleLoginAuthID = (ret_item) => {
    if (ret_item !== undefined && ret_item !== "") {
      sessionStorage.setItem("access_detals", ret_item);
      setAuthID(ret_item);
      setLoginStatus("LOGGINSUCCESS");
    }
  };

  const handleLogout = (e) => {
    if (e) {
      setAuthID(null);
      sessionStorage.clear();
      setLoginStatus("NOTLOGGEDIN");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };

  useEffect(() => {
    if (pathname !== null) {
      handleAuthID({ authID: aid, phone: phone });
      if (
        [
          "ematchingprofile",
          "ematchingprofileresponse",
          "matchProfLand",
          "matchProfView",
          "Receiver_Sender_Confirmation"
        ].some((pathURL) => pathname.startsWith(pathURL))
      ) {
        navigate(pathname, { state: { uqURL: uqURL }, replace: true });
      } else {
        navigate(pathname, {
          state: { authID: aid, phone: phone },
        });
      }
    }
  }, [pathname]);
  // useEffect(() => {
  //   const restrictedPaths = [
  //     "ematchingprofile",
  //     "ematchingprofileresponse",
  //     "matchProfLand",
  //     "matchProfView",
  //     "preference",
  //   ];

  //   const handlePopState = (event) => {
  //     if (restrictedPaths.some((path) => location.pathname.startsWith(path))) {
  //       navigate(location.pathname, { replace: true });
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [location.pathname, navigate]);


  return (
    <div className="App overflow-hidden">
      <ScrollToTop />
      <AccessContext.Provider
        value={{
          authID: authID,
          handleLogout: handleLogout,
          setAuthID: handleAuthID,
        }}
      >
        <Routes>
          <Route exact path="/" element={<SignUp onLogOut={handleLogout} />} />
          <Route
            exact
            path="/login"
            element={<Login onLogin={handleLoginAuthID} />}
          />
          <Route
            exact
            path="/join"
            element={<Login onLogin={handleLoginAuthID} />}
          />
          <Route
            exact
            path="/personalinfo"
            element={<PersonalInfoPage loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/sgpersonalinfo"
            element={<SgPersonalInfo loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/sgpersonalinfoimg"
            element={<SgPersonalInfoImage loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/signuptermsofuse"
            element={<SignupTermsOfUse loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/subscriptionrate"
            element={<SubscriptionRate loginStatus={loginStatus} />}
          />
          <Route
            path="/pmtfailed"
            element={<PaymentFailed loginStatus={loginStatus} />}
          />
          <Route
            path="/pmtresult"
            element={<PaymentResult loginStatus={loginStatus} />}
          />
          <Route
           exact
            path="/preference"
            element={<Preference loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/subscription"
            element={<Subscription loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/termofservice"
            element={<TermsOfService loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/termsofuse"
            element={<TermsOfUse loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/ematchingprofile"
            element={<EmailProfileConfirmation loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/ematchingprofileresponse"
            element={
              <EmailProfileConfirmedResponse loginStatus={loginStatus} />
            }
          />
          {/* <Route
            exact
            path="/verfication"
            element={<Verification accessId={handleAuthID} />}
          /> */}

          <Route
            exact
            path="/verifycode/:code/:phone"
            element={<VerificationCode />}
          />

          <Route
            exact
            path="/privacy"
            element={<Privacy loginStatus={loginStatus} />}
          />
          {/* Match Profile */}
          <Route
            exact
            path="/matchProfLand/:id"
            element={<ProfileStep1 loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/matchProfView/:id"
            element={<ProfileStep2 loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/ReceiverConfirmMyInterest"
            element={<ProfileStep4 loginStatus={loginStatus} />}
          />
          {/* Intro Profile */}
          <Route
            exact
            path="/SenderConfirmMyInterest"
            element={<ProfileStep5 loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/intProfView/:id"
            element={<ProfileStep6 loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/intProfLand/:id"
            element={<ProfileStep7 loginStatus={loginStatus} />}
          />
          <Route
            exact
            path="/Receiver_Sender_Confirmation/:id"
            element={<ProfileStep3 loginStatus={loginStatus} />}
          />
        </Routes>
      </AccessContext.Provider>
    </div>
  );
}

export default App;
