import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import $, { event } from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import { AccessContext } from "../constant/AccessContext";
import DatePicker from "react-datepicker";
import moment, { max } from "moment";
import "react-datepicker/dist/react-datepicker.css";
// import stateDist from "../constant/state-dist.json";
// import religion from "../constant/religion.json";
// import language from "../constant/languages.json";
import { Dropdown } from "primereact/dropdown";

import { ToastContainer, toast } from "react-toastify";
import {
  incomelist,
  languagelist,
  religionlist,
  setpersonalinfo,
  statelist,
} from "../constant/url";
import "../css/common.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { HeaderLink } from "../component/HeaderLink";

export const SgPersonalInfo = (props) => {
  let navigate = useNavigate();
  const state = useLocation()["state"];
  let propData = { ...state, login: false };

  const [languages, setLanguages] = useState([]);
  const [religions, setReligions] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [states, setStates] = useState([]);

  const access = propData?.authID;
  const fname = propData?.fname;
  const [dOBerr, setDOBerr] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const monthOpction = [
    { name: "January", code: "JAN", month: "01" },
    { name: "February", code: "FEB", month: "02" },
    { name: "March", code: "MAR", month: "03" },
    { name: "April", code: "APR", month: "04" },
    { name: "May", code: "MAY", month: "05" },
    { name: "June", code: "JUN", month: "06" },
    { name: "July", code: "JUL", month: "07" },
    { name: "August", code: "AUG", month: "08" },
    { name: "September", code: "SEP", month: "09" },
    { name: "October", code: "OCT", month: "10" },
    { name: "November", code: "NOV", month: "11" },
    { name: "December", code: "DEC", month: "12" },
  ];

  const dayOpction = [
    { code: "01" },
    { code: "02" },
    { code: "03" },
    { code: "04" },
    { code: "05" },
    { code: "06" },
    { code: "07" },
    { code: "08" },
    { code: "09" },
    { code: "10" },
    { code: "11" },
    { code: "12" },
    { code: "13" },
    { code: "14" },
    { code: "15" },
    { code: "16" },
    { code: "17" },
    { code: "18" },
    { code: "19" },
    { code: "20" },
    { code: "21" },
    { code: "22" },
    { code: "23" },
    { code: "24" },
    { code: "25" },
    { code: "26" },
    { code: "27" },
    { code: "28" },
    { code: "29" },
    { code: "30" },
    { code: "31" },
  ];

  const yearopctions = [
    { code: "2006" },
    { code: "2005" },
    { code: "2004" },
    { code: "2003" },
    { code: "2002" },
    { code: "2001" },
    { code: "2000" },
    { code: "1999" },
    { code: "1998" },
    { code: "1997" },
    { code: "1996" },
    { code: "1995" },
    { code: "1994" },
    { code: "1993" },
    { code: "1992" },
    { code: "1991" },
    { code: "1990" },
    { code: "1989" },
    { code: "1988" },
    { code: "1987" },
    { code: "1986" },
    { code: "1984" },
    { code: "1983" },
    { code: "1982" },
    { code: "1981" },
    { code: "1980" },
    { code: "1979" },
    { code: "1978" },
    { code: "1977" },
    { code: "1976" },
    { code: "1975" },
    { code: "1974" },
    { code: "1973" },
    { code: "1972" },
    { code: "1971" },
    { code: "1970" },
    { code: "1969" },
    { code: "1968" },
    { code: "1967" },
    { code: "1966" },
    { code: "1965" },
    { code: "1964" },
    { code: "1963" },
    { code: "1962" },
    { code: "1961" },
    { code: "1950" },
  ];
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const [retMsg, setRetMsg] = useState({ type: "", msg: "" });
  const [loading, setLoading] = useState(false);
  const [inputFocusState, setInputFocusState] = useState({});

  const handleFocus = (inputId) => {
    setInputFocusState((prevFocusState) => ({
      ...prevFocusState,
      [inputId]: true,
    }));
  };

  const handleBlur = (inputId) => {
    setInputFocusState((prevFocusState) => ({
      ...prevFocusState,
      [inputId]: false,
    }));
  };

  const handleNameInput = (event, id) => {
    const inputValue = event.target.value;
    const isValid = /^[a-zA-Z\s]*$/.test(inputValue);
    if (isValid) {
      setValue(id, inputValue);
    } else {
      setValue(id, inputValue.replace(/[^a-zA-Z\s]/g, ""));
    }
  };

  const handleStringWithDotAndComma = (event, field) => {
    const inputValue = event.target.value;
    const isValid = /^[a-zA-Z.,\s]*$/.test(inputValue);

    if (isValid) {
      setValue(field, inputValue);
    } else {
      const sanitizedValue = inputValue.replace(/[^a-zA-Z.,\s]+/g, "");
      setValue(field, sanitizedValue);
    }
  };

  const weightComp = () => {
    let elem = [];
    for (let i = 20; i <= 200; i++) {
      elem.push(<option value={i + " Kg"}>{i + " Kg"}</option>);
    }
    return elem;
  };

  const heightComp = () => {
    let elem = [];
    for (let i = 100; i <= 210; i++) {
      elem.push(<option value={i + " Cm"}>{i + " Cm"}</option>);
    }
    return elem;
  };
  const onSubmit = async (data) => {
    const {
      fname,
      email,
      gender,
      maritalSts,
      // dob,
      language,
      religion,
      edu_qual,
      profession,
      annual_income,
      food,
      smoke,
      drink,
      height,
      weight,
      city,
      state,
      country,
    } = data;
    // const formatDOB = moment(dob).format("YYYY-MM-DD");
    if (!year) {
      setDOBerr("Year is a required field");
      return;
    }
    if (!month) {
      setDOBerr("Month is a required field");
      return;
    }
    if (!day) {
      setDOBerr("Day is a required field");
      return;
    }
    setDOBerr("");
    const formatDOB = `${year.code}-${month.month}-${day.code}`;

    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("authId", access);
    bodyFormData.append("fname", fname);
    bodyFormData.append("email", email);
    bodyFormData.append("gender", gender);
    bodyFormData.append("maritalSts", maritalSts);
    bodyFormData.append("dob", formatDOB);
    bodyFormData.append("language", language);
    bodyFormData.append("religion", religion);
    bodyFormData.append("edu_qual", edu_qual);
    bodyFormData.append("profession", profession);
    bodyFormData.append("annual_income", annual_income);
    bodyFormData.append("food", food);
    bodyFormData.append("height", height);
    bodyFormData.append("weight", weight);
    bodyFormData.append("smoke", smoke);
    bodyFormData.append("drink", drink);
    bodyFormData.append("city", city);
    bodyFormData.append("state", state);
    bodyFormData.append("country", country);
    bodyFormData.append("comp_lev", 2);

    await axios({
      method: "post",
      url: setpersonalinfo,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const res_data = response.data;
        if (res_data.status_code === 101) {
          navigate("/login");
        } else if (res_data.status_code === 200) {
          // toast.success(res_data.status_msg);
          sessionStorage.setItem("access_detals", access);
          setTimeout(() => {
            if (!propData?.login) {
              navigate("/sgpersonalinfoimg", { state: propData });
            }
          }, 2000);

          setRetMsg({ type: "success", msg: res_data.status_msg });
        } else {
          setRetMsg({ type: "error", msg: res_data.status_msg });
          toast.error(res_data.status_msg);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (propData?.authID === undefined || !propData?.authID) {
      navigate("/login");
    }

    // if (propData?.acctype !== "signup") {
    // 	navigate("/personalinfo", { state: propData });
    // }
  }, [access]);

  useEffect(() => {
    setValue("fname", fname);
  }, []);

  useEffect(() => {
    if (access) {
      axios({
        method: "post",
        url: languagelist,
        data: {
          authId: access.toString(),
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   lang_id: number;
            //   lang_name: String;
            // }
            setLanguages(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch languages", response);
        });

      axios({
        method: "post",
        url: religionlist,
        data: {
          authId: access.toString(),
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   relgs_id: number;
            //   relgs_name: String;
            // }
            setReligions(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch religions", response);
        });

      axios({
        method: "post",
        url: incomelist,
        data: {
          authId: access,
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   incom_id: number;
            //   incom_name: String;
            // }
            setIncomes(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch incomes", response);
        });

      axios({
        method: "post",
        url: statelist,
        data: {
          authId: access.toString(),
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            // {
            //   stat_id: number;
            //   stat_name: String;
            // }
            setStates(res_data.data);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Unable to fetch states", response);
        });
    }
  }, [access]);

  useEffect(() => {
    const disableBackButton = (e) => {
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = function (event) {
        window.scrollTo(-100, 0);
        window.history.pushState(null, document.title, window.location.href);
      };
    };

    disableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, []);
  const handleSpacePrevent = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };
  const handleLoginRegister = (e) => {
    if (e === "login") {
      navigate("/login");
    }
  };
  return (
    <>
      <HeaderLink ret_type={(e) => {}} acc_type={"logout"} />
      <div className="bg-container">
        <div className="container d-flex justify-content-center commonContainer customPer ">
          <div className="divider"></div>
          <div className="main-div">
            <div>
              <div className="row personalHeader personalHeader_cur">
                <div className="col-md-5 col-4 d-flex justify-content-end">
                  <div class="profile">
                    <div class="outer">
                      <div class="inner">
                        <div id="number">25%</div>
                      </div>
                    </div>
                    <svg width="70px" height="70px">
                      <circle
                        className="profile25"
                        cx="35"
                        cy="35"
                        r="30"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-md-6 col-7 p-0 perTxt">
                  <h2 className="m-0 otpBttVer">Personal Details</h2>
                  <p className="m-0">Enter your details below</p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7">
                        <div
                          className={`inputField nameField ${
                            errors.fname ? "borderError" : ""
                          } ${inputFocusState.fname ? "inputFocused" : ""}`}
                        >
                          <input
                            {...register("fname", {
                              required: "Name is required",
                              minLength: {
                                value: 2,
                                message: "Enter Valid Name",
                              },
                              maxLength: {
                                value: 30,
                                message: "Enter Valid Name",
                              },
                              pattern: {
                                value: /^[A-Za-z ]+$/,
                                message: "Enter Valid Name",
                              },
                            })}
                            maxLength="30"
                            type="text"
                            className={`form-control firstFormField p-2 ${
                              errors.fname ? "is-invalid-input" : ""
                            }`}
                            placeholder="Name"
                            id="fname"
                            onInput={(e) => handleNameInput(e, "fname")}
                            onFocus={() => handleFocus("fname")}
                            onBlur={() => handleBlur("fname")}
                          />
                        </div>
                        {errors.fname && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0 mt-1">
                            {errors.fname.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput mailField ${
                            errors.email ? "borderError" : ""
                          } ${inputFocusState.email ? "inputFocused" : ""}`}
                        >
                          <input
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                            type="email"
                            className={`form-control custom-margin-top p-2 ${
                              errors.email ? "is-invalid-input" : ""
                            }`}
                            placeholder="Email"
                            id="email"
                            onFocus={() => handleFocus("email")}
                            onBlur={() => handleBlur("email")}
                            onKeyDown={handleSpacePrevent}
                            maxLength="50"
                          />
                        </div>
                        {errors.email && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput genderField ${
                            errors.gender ? "borderError" : ""
                          } ${inputFocusState.gender ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("gender", {
                              required: "Gender is required",
                            })}
                            key={"gender"}
                            className={`form-select custom-margin-top p-2 ${
                              errors.gender ? "is-invalid-input" : ""
                            }`}
                            aria-label="Gender"
                            id="gender"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        {errors.gender && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.gender.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput maritalField ${
                            errors.maritalSts ? "borderError" : ""
                          } ${
                            inputFocusState.maritalSts ? "inputFocused" : ""
                          }`}
                        >
                          <select
                            {...register("maritalSts", {
                              required: "Marital Status is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.maritalSts ? "is-invalid-input" : ""
                            }`}
                            aria-label="Language"
                            id="maritalSts"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Marital Status
                            </option>
                            <option value="Never Married">Never Married</option>
                            <option value="Widowed ">Widowed</option>
                            <option value="Divorced">Divorced</option>
                          </select>
                        </div>
                        {errors.maritalSts && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.maritalSts.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput ${
                            errors.dob ? "borderError" : ""
                          } ${inputFocusState.dob ? "inputFocused" : ""}`}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "18px",
                              overflow: "auto",
                            }}
                            className="Custom_dob"
                          >
                            <div
                              className="form-control"
                              style={{
                                width: "unset",

                                padding: "0px 15px",
                                display: "flex",
                                alignItems: "center",
                                height: "40px",
                              }}
                            >
                              <div className=" dobField">
                                <div className="dOB"></div>
                              </div>
                            </div>

                            <Dropdown
                              value={day}
                              onChange={(e) => setDay(e.value)}
                              options={dayOpction}
                              optionLabel="code"
                              placeholder={windowWidth <= 575 ? "D" : "Day"}
                              className="w-full md:w-14rem form-control"
                              style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                border: dOBerr
                                  ? "1px solid #d93025"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <Dropdown
                              value={month}
                              onChange={(e) => setMonth(e.value)}
                              options={monthOpction}
                              optionLabel="code"
                              placeholder={windowWidth <= 575 ? "M" : "Month"}
                              className="w-full md:w-14rem form-control"
                              style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                border: dOBerr
                                  ? "1px solid #d93025"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <Dropdown
                              value={year}
                              onChange={(e) => setYear(e.value)}
                              options={yearopctions}
                              optionLabel="code"
                              placeholder={windowWidth <= 575 ? "Y" : "Year"}
                              className="w-full md:w-14rem form-control"
                              style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                border: dOBerr
                                  ? "1px solid #d93025"
                                  : "1px solid #dee2e6",
                              }}
                            />
                          </div>
                        </div>
                        {errors.dob && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.dob.message}
                          </p>
                        )}
                        {dOBerr && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {dOBerr}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput langField ${
                            errors.language ? "borderError" : ""
                          } ${inputFocusState.language ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("language", {
                              required: "Language is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.language ? "is-invalid-input" : ""
                            }`}
                            aria-label="Language"
                            id="language"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Language
                            </option>
                            {languages.map((item) => {
                              return (
                                <option value={item.lang_id}>
                                  {item.lang_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {errors.language && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.language.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput relegionField ${
                            errors.religion ? "borderError" : ""
                          } ${inputFocusState.religion ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("religion", {
                              required: "Religion is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.religion ? "is-invalid-input" : ""
                            }`}
                            aria-label=""
                            id="religion"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Religion
                            </option>
                            {religions.map((item) => {
                              return (
                                <option value={item.relgs_id}>
                                  {item.relgs_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {errors.religion && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.religion.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput educationField ${
                            errors.edu_qual ? "borderError" : ""
                          } ${inputFocusState.edu_qual ? "inputFocused" : ""}`}
                        >
                          <input
                            {...register("edu_qual", {
                              required: "Educ. Qualification is required",
                              minLength: {
                                value: 2,
                                message: "Enter Valid Educ. Qualification",
                              },
                              maxLength: {
                                value: 30,
                                message: "Enter Valid Educ. Qualification",
                              },
                              pattern: {
                                value: /^[A-Za-z., ]+$/,
                                message: "Enter Valid Educ. Qualification",
                              },
                            })}
                            type="text"
                            className={`form-control custom-margin-top p-2 ${
                              errors.edu_qual ? "is-invalid-input" : ""
                            }`}
                            placeholder="Education"
                            id="edu_qual"
                            onInput={(event) =>
                              handleStringWithDotAndComma(event, "edu_qual")
                            }
                            onFocus={() => handleFocus("edu_qual")}
                            onBlur={() => handleBlur("edu_qual")}
                            maxLength="30"
                          />
                        </div>
                        {errors.edu_qual && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0 mt-1">
                            {errors.edu_qual.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput professionField ${
                            errors.profession ? "borderError" : ""
                          } ${
                            inputFocusState.profession ? "inputFocused" : ""
                          }`}
                        >
                          <input
                            {...register("profession", {
                              required: "Proffession is required",
                              minLength: {
                                value: 2,
                                message: "Enter Valid Proffession",
                              },
                              maxLength: {
                                value: 30,
                                message: "Enter Valid Proffession",
                              },
                              pattern: {
                                value: /^[A-Za-z ]+$/,
                                message: "Enter Valid Proffession",
                              },
                            })}
                            type="text"
                            maxLength="30"
                            className={`form-control custom-margin-top p-2 ${
                              errors.profession ? "is-invalid-input" : ""
                            }`}
                            placeholder="Profession"
                            id="profession"
                            onInput={(event) =>
                              handleStringWithDotAndComma(event, "profession")
                            }
                            onFocus={() => handleFocus("profession")}
                            onBlur={() => handleBlur("profession")}
                          />
                        </div>
                        {errors.profession && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0 mt-1">
                            {errors.profession.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput incomeField ${
                            errors.annual_income ? "borderError" : ""
                          } ${
                            inputFocusState.annual_income ? "inputFocused" : ""
                          }`}
                        >
                          <select
                            {...register("annual_income", {
                              required: "Annual Income is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.annual_income ? "is-invalid-input" : ""
                            }`}
                            aria-label="Annual Income"
                            id="annual_income"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Annual Income
                            </option>
                            {incomes.map((item) => (
                              <option value={item.incom_id}>
                                {item.incom_name}
                              </option>
                            ))}
                            {/* <option value="No Income">No Income</option>
                            <option value="Upto 2 Lakhs">
                              Upto Rs.2 Lakhs
                            </option>
                            <option value="2 Lakhs - 5 Lakhs">
                              Rs.2 Lakhs - Rs.5 Lakhs
                            </option>
                            <option value="5 Lakhs - 10 Lakhs">
                              Rs.5 Lakhs - Rs.10 Lakhs
                            </option>
                            <option value="10 Lakhs - 18 Lakhs">
                              Rs.10 Lakhs - Rs.18 Lakhs
                            </option>
                            <option value="18 Lakhs - 30 Lakhs">
                              Rs.18 Lakhs - Rs.30 Lakhs
                            </option>
                            <option value="30 Lakhs - 50 Lakhs">
                              Rs.30 Lakhs - Rs.50 Lakhs
                            </option>
                            <option value="50 Lakhs - 1 Crore">
                              Rs.50 Lakhs - Rs.1 Crore
                            </option>
                            <option value="Above 1 Crore">
                              Above Rs.1 Crore
                            </option> */}
                          </select>
                        </div>
                        {errors.annual_income && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.annual_income.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput foodField ${
                            errors.food ? "borderError" : ""
                          } ${inputFocusState.food ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("food", {
                              required: "Food is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.food ? "is-invalid-input" : ""
                            }`}
                            aria-label="Food"
                            id="food"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Food
                            </option>
                            <option value="Non-Vegetarian">
                              Non-Vegetarian
                            </option>
                            <option value="Vegetarian">Vegetarian</option>
                            <option value="Vegan">Vegan</option>
                          </select>
                        </div>
                        {errors.food && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.food.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput smokeField ${
                            errors.smoke ? "borderError" : ""
                          } ${inputFocusState.smoke ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("smoke", {
                              required: "Smoke is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.smoke ? "is-invalid-input" : ""
                            }`}
                            aria-label="Smoke"
                            id="smoke"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Smoke
                            </option>
                            <option value="Never">Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Regular">Regular</option>
                          </select>
                        </div>
                        {errors.smoke && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.smoke.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput drinkField ${
                            errors.drink ? "borderError" : ""
                          } ${inputFocusState.drink ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("drink", {
                              required: "Drink is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.drink ? "is-invalid-input" : ""
                            }`}
                            aria-label="Drink"
                            id="drink"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Drinking
                            </option>
                            <option value="Never">Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Regular">Regular</option>
                          </select>
                        </div>
                        {errors.drink && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.drink.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput heightField ${
                            errors.height ? "borderError" : ""
                          } ${inputFocusState.height ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("height", {
                              required: "Height is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.height ? "is-invalid-input" : ""
                            }`}
                            aria-label="Height"
                            id="height"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Height
                            </option>
                            {heightComp()}
                          </select>
                        </div>
                        {errors.height && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.height.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput weightField ${
                            errors.weight ? "borderError" : ""
                          } ${inputFocusState.weight ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("weight", {
                              required: "Weight is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.weight ? "is-invalid-input" : ""
                            }`}
                            aria-label="Weight"
                            id="weight"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              Weight
                            </option>
                            {weightComp()}
                          </select>
                        </div>
                        {errors.weight && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.weight.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput cityField ${
                            errors.city ? "borderError" : ""
                          } ${inputFocusState.city ? "inputFocused" : ""}`}
                        >
                          <input
                            {...register("city", {
                              required: "City is required",
                              minLength: {
                                value: 2,
                                message: "Enter Valid City Name",
                              },
                              maxLength: {
                                value: 30,
                                message: "Enter Valid City Name",
                              },
                              pattern: {
                                value: /^[A-Za-z ]+$/,
                                message: "Enter Valid City Name",
                              },
                            })}
                            maxLength="30"
                            type="text"
                            className={`form-control custom-margin-top p-2 ${
                              errors.city ? "is-invalid-input" : ""
                            }`}
                            placeholder="City"
                            id="city"
                            onInput={(e) => handleNameInput(e, "city")}
                            onFocus={() => handleFocus("city")}
                            onBlur={() => handleBlur("city")}
                          />
                        </div>
                        {errors.city && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0 mt-1">
                            {errors.city.message}
                          </p>
                        )}
                      </div>
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput locField ${
                            errors.state ? "borderError" : ""
                          } ${inputFocusState.state ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("state", {
                              required: "State is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.state ? "is-invalid-input" : ""
                            }`}
                            aria-label="State"
                            id="state"
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="optionsHead"
                            >
                              State
                            </option>
                            {states.map((item) => {
                              return (
                                <option value={item.stat_id}>
                                  {item.stat_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {errors.state && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.state.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 col-md-7 custom_inputBar">
                        <div
                          className={`inputField fieldInput countryField ${
                            errors.country ? "borderError" : ""
                          } ${inputFocusState.country ? "inputFocused" : ""}`}
                        >
                          <select
                            {...register("country", {
                              required: "Country is required",
                            })}
                            className={`form-select custom-margin-top p-2 ${
                              errors.country ? "is-invalid-input" : ""
                            }`}
                            aria-label="Country"
                            id="country"
                          >
                            <option value="" disabled className="optionsHead">
                              Country
                            </option>
                            <option selected value="India">
                              India
                            </option>
                          </select>
                        </div>
                        {errors.country && (
                          <p className="is-invalid-text text-nowrap text-nowrap text-capitalize mb-0">
                            {errors.country.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="d-grid gap-2  mx-auto firstFormField">
                      <button 
                        type="submit"
                        className="btn btn-primary bg-dark p-2 cus_btn"
                        disabled={loading}
                      >
                        {!loading ? "SUBMIT": "Loading..."}
                      </button>
                      {/* <div>
                        <p className={`text-center ${retMsg.type}`}>
                          {retMsg.msg}
                        </p>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row mt-sm-4 mb-sm-3">
              <div className="col">
                <div className="col d-flex justify-content-start">
                  <img
                    className="img-fluid btm-flower-left"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img
                    className="img-fluid btm-flower-right"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block personal-footer">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
