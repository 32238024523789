import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { resetPasswordURL } from "../constant/url";
import { useNavigate } from "react-router-dom";
import "../css/newStyle.css"

function ResetCompleteForm(props) {
    const navigate = useNavigate();

    return (
        <form
        className="login-left mt-0 mt-md-5 mt-lg-2 pt-2"
        method="post"
      >
        <div className="rectangle rectangle4" />
        <div className="rectangle rectangle3" />
        <div className="rectangle rectangle2" />
        <div className="rectangle rectangle1 pt-5">
          <h2 className="text-center forgotPass">Reset Completed</h2>
          <p className="text-center lg-text paraText_cutom">Your password has been successfully reset.
 </p>
          <p className="text-center lg-text paraText_cutom">Please proceed to login with your new credentials</p>
          <div className="tickImg">
          <img
              style={{top: "90%"}}
            width="66px"
            height="66px"
            
              alt="Mask group"
              src={require("../assets/img/signup/Group 1120.png")}
            />
          </div>
          <div>
          <div className="custom_btnss">
              <button
                className="btn btn-primary bg-dark text-white custom_back"
                type="button"
                onClick={() => navigate("/login")}
              >
                Back to Home
              </button>
            </div>
          </div>
         
          <div className="d-flex justify-content-center pt-1">
          <img
              className="btm-flower"
              alt="Mask group"
              width="66px"
              height="66px"
              src={require("../assets/img/signup/mask-group.png")}
            />
          </div>
        </div>
      </form>
    );
}

function ResetForm(props) {
    const { code, setSuccess } = props;
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState({ type: "", msg: "" });
    const [inputFocusState, setInputFocusState] = useState({});

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm();

    const handleFocus = (inputId) => {
        setInputFocusState((prevFocusState) => ({
            ...prevFocusState,
            [inputId]: true,
        }));
    };

    const handleBlur = (inputId) => {
        setInputFocusState((prevFocusState) => ({
            ...prevFocusState,
            [inputId]: false,
        }));
    };

    const handlePasswordInput = (event, id) => {
        const inputValue = event.target.value;
        const isValid = /^[a-zA-Z0-9\s]*$/.test(inputValue);
        if (isValid) {
            setValue(id, inputValue);
        } else {
            setValue(id, inputValue.replace(/[^a-zA-Z0-9\s]/g, ""));
        }
    };

    const onSubmitConfirmPassword = (data) => {
        if (data.newPassword !== data.confirmPassword) {
            setError("confirmPassword", {
                type: "manual",
                message: "Passwords must match",
            });
            return;
        }
        setLoading(true);

        var bodyFormData = new FormData();
        bodyFormData.append("new_pass", data.newPassword.trim());
        bodyFormData.append("verify_code", code);

        axios({
            method: "post",
            url: resetPasswordURL,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
            console.log(response);
            if (response.data.status_code === 200) {
                setSuccess(true);
            } else {
                setErrorMsg({ type: "", msg: response.data.status_msg});
            }
            setLoading(false);
        });
    };

    return (<form
        className="login-left mt-0 mt-md-5 mt-lg-2 pt-2"
        onSubmit={handleSubmit(onSubmitConfirmPassword)}
        method="post"
    >
        <div className="rectangle rectangle4" />
        <div className="rectangle rectangle3" />
        <div className="rectangle rectangle2" />
        <div className="rectangle rectangle1 pt-5">
            <h2 className="text-center">Reset Password</h2>
            <p className="text-center lg-text">
                Your new password must be different to previous passwords
            </p>

            <div
                className={`mx-2 mx-md-5 mb-3 inputField passwordField ${errors.phone ? "borderError" : ""
                    } ${inputFocusState.newPassword ? "inputFocused" : ""}`}
            >
                <input
                    {...register("newPassword", {
                        required: "Password is required",
                        minLength: {
                            value: 6,
                            message: "Minimum 6 characters",
                        },
                        maxLength: {
                            value: 30,
                            message: "Maximum 30 characters",
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9]+$/,
                            message: "Password must be with characters and numbers",
                        },
                    })}
                    className={`form-control p-2 ${errors.newPassword ? "border-danger" : "border border-1"
                        }`}
                    id="newPassword"
                    placeholder="New Password"
                    onInput={(e) => handlePasswordInput(e, "newPassword")}
                    onFocus={() => handleFocus("newPassword")}
                    onBlur={() => handleBlur("newPassword")}
                />
                {errors.newPassword && (
                    <div className="d-flex">
                        <p className="errorField">{errors.newPassword.message}</p>
                    </div>
                )}
            </div>
            <div
                className={`mx-2 mx-md-5 inputField passwordField ${errors.confirmPassword ? "borderError" : ""
                    } ${inputFocusState.confirmPassword ? "inputFocused" : ""}`}
            >
                <input
                    {...register("confirmPassword", {
                        required: "Please enter password",
                        minLength: {
                            value: 6,
                            message: "Minimum 6 characters",
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9]+$/,
                            message: "Password must be with characters and numbers",
                        },
                    })}
                    type="password"
                    className={`form-control p-2 ${errors.confirmPassword ? "border-danger" : "border border-1"
                        }`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    onInput={(e) => handlePasswordInput(e, "confirmPassword")}
                    onFocus={() => handleFocus("confirmPassword")}
                    onBlur={() => handleBlur("confirmPassword")}
                />
            </div>
            <div className="col d-flex justify-content-between mx-2 mx-md-5">
                <div className="d-flex">
                    {errors.confirmPassword && (
                        <p className="errorField">{errors.confirmPassword.message}</p>
                    )}
                </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
                <div className="d-grid gap-2 col-7 mx-5 mt-3">
                    <button
                        className="btn btn-primary bg-dark text-white"
                        type="submit"
                        disabled={loading}
                    >
                        RESET PASSWORD
                    </button>
                    {errorMsg.msg && (
                        <p className="errorField text-center">{errorMsg.msg}</p>
                    )}
                </div>
            </div>
            <div className="d-flex justify-content-center pt-1">
                <img
                    className="btm-flower"
                    alt="Mask group"
                    width="66px"
                    height="66px"
                    src={require("../assets/img/signup/mask-group.png")}
                />
            </div>
        </div>
    </form>);
}

function Verify(props) {
    const { code } = props;

    const [success, setSuccess] = useState(false);

    let formComponent = success ? <ResetCompleteForm /> : <ResetForm code={code} setSuccess={setSuccess} />;


    return (
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 position-relative d-flex justify-content-center pt-0 mt-xl-5">
            {formComponent}
        </div>
    );
}

export default Verify;
