import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { signup } from "../constant/url";
import "../css/signup.css";
import "../css/newStyle.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import SliderProfile from "./SliderProfile";
import { HeaderLink } from "../component/HeaderLink";

export default function SignUp(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   props.onLogOut(true);
  // }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const onSubmit = async (data) => {
    const { name, phone } = data;
    setLoading(true);

    var bodyFormData = new FormData();
    bodyFormData.append("name", name.trim());
    bodyFormData.append("phone", phone);

    await axios({
      method: "post",
      url: signup,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const res_data = response.data;
        if (res_data.status_code === 101) {
          console.log("Api Authentication failed. login again.");
        } else if (res_data.status_code === 200) {
          console.log("Signup accepted, Proceeding to verification.");
          navigate("/sgpersonalinfo", {
            state: {
              authID: res_data.authId,
              phone: phone,
              fname: name.trim(),
              acctype: "signup",
            },
          });
        } else if (res_data.status_code === 416) {
          setErrorText(416);
        } else {
          setErrorText(res_data.status_msg);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    setLoading(false);
  };

  const handleLoginRegister = (e) => {
    if (e === "login") {
      navigate("/login");
    }
  };

  const handleNameInput = (event) => {
    let inputValue = event.target.value;
    const isValid = /^[a-zA-Z\s]*$/.test(inputValue);
    if (isValid) {
      inputValue = inputValue.slice(0, 30);
    } else {
      inputValue = inputValue.replace(/[^a-zA-Z\s]/g, "").slice(0, 30);
    }
    setValue("name", inputValue);
  };

  const handleNumberinput = (event) => {
    let inputValue = event.target.value;
    const isValid = /^[6789]\d{0,9}$/.test(inputValue);
    if (isValid) {
      // Ensure the length does not exceed 10 characters
      inputValue = inputValue.slice(0, 10);
    } else {
      // Remove non-numeric characters and ensure the length does not exceed 10 characters
      inputValue = inputValue.replace(/[^6789\d]/g, "").slice(0, 10);
    }
    setValue("phone", inputValue);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabChange = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleTabChangeC = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const isTabOpen = (index) => activeIndex === index;
  const condentText = (headerText, isOpen) => (
    <div
      className={`${isOpen ? "open-class-mt-drop-icon" : "mt-drop-icon"}`}
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
        lineHeight: "20px",
      }}
    >
      <div
        className={` ${isOpen ? "open-class-mt-drop-text" : "mt-drop-text mob_FAQ"
          }`}
      >
        {headerText}
      </div>
      <div>
        {isOpen ? (
          <img
            style={{ width: "15px", rotate: "180deg" }}
            src={require("../assets/img/Vector.png")}
          />
        ) : (
          <img
            style={{ width: "15px" }}
            src={require("../assets/img/Vector.png")}
          />
        )}
      </div>
    </div>
  );
  return (
    <>
      {/* <Header acc_type="login" ret_type={(e) => handleLoginRegister(e)} /> */}
        <HeaderLink acc_type="login" ret_type={(e) => handleLoginRegister(e)} />
      <div className="SIGNUP">
        <div className="BodySection">
          <div className="container container-width ">
            <div className="row mt-0 mt-sm-5">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 position-relative d-flex justify-content-sm-center justify-content-lg-start">
                <div className="first-block-left custom_tesRes">
                  <p className="custom_rep_tt" style={{ color: "#141414" }}>
                    Famile
                  </p>
                  <p className="title custom_text_desk" style={{ color: "#141414" }}>
                    Famile
                  </p>
                  <p className="resp_par">
                    Famile is a community, a matrimonial network for people who
                    do not believe in caste.
                  </p>
                  <p className="resp_par2">
                    With a vision to foster meaningful connections based on
                    positive values and journeys, our network sincerely cares
                    for the genuinely good people.
                  </p>
                  <div className="row mt-lg-5 pt-3">
                    <div className="col">
                      <p className="para text-start mb-0">
                        <strong className="bold-title">1100</strong> avg.
                      </p>
                      <p className="para text-start resp_text">
                        Registrations/Week
                      </p>
                    </div>
                    <div className="col">
                      <p className="para mb-0">
                        <strong className="bold-title">83%</strong>
                      </p>
                      <p className="para resp_text">Success Rate</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0">
                <div className="first-block-right">
                  <img
                    className="bg-dots d-none d-sm-none d-md-none d-lg-block d-xl-block"
                    alt="Famile mm"
                    src={require("../assets/img/group-1088.png")}
                  />
                  {/* <img
                    className="vec-heart img-fluid customHeart"
                    alt="Famile mm"
                    src={require("../assets/img/signup/Vector.png")}
                  /> */}
                  <div className="col custom_grid">
                    <div>
                      <img
                        className="img4 img-h-full img-fluid  custom_img_banner"
                        alt="Famile mm"
                        src={require("../assets/img/signup/banner_img.png")}
                      />
                      {/* <div className="d-flex justify-content-center">
                        <div className="col-5 d-flex justify-content-center ">
                          <img
                            className="img1 img-fluid  customImgHeight1"
                            alt="Famile mm"
                            src={require("../assets/img/signup/1_15_11zon.png")}
                          />
                        </div>
                        <div className="col-7 d-flex justify-content-center ">
                          <img
                            className="img4 img-h-full img-fluid  customImgHeight2"
                            alt="Famile mm"
                            src={require("../assets/img/signup/2_16_11zon.png")}
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* <div className=""> */}
                      {/* <div className="d-flex justify-content-center">
                        <div className="col-7 d-flex justify-content-center">
                          <img
                            className="img2  img-fluid  pt-0 customImgHeight3"
                            alt="Famile mm"
                            src={require("../assets/img/signup/3_17_11zon.png")}
                          />
                        </div>
                        <div className="col-5">
                          <img
                            className="img3 img-fluid   pt-0 customImgHeight4"
                            alt="Hands indian bride"
                            src={require("../assets/img/signup/4_18_11zon.png")}
                          />
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex second-block section">
              <div className="col-10 col-md-4 col-lg-3 d-flex justify-content-center p-2">
                <div className="card">
                  <div className="card-body p-3 customBox_slide">
                    <div className="CommunSec">
                      <img
                        className="box-icon "
                        alt="Famile mm"
                        src={require("../assets/img/signup/rate-2.png")}
                      />
                      <h2>Community</h2>
                    </div>

                    <p className="mt-4">
                      Our community is peaceful and rational. People joining our
                      community are fostering life-time bonds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-5 col-lg-3 d-flex justify-content-center p-2">
                <div className="card">
                  <div className="card-body p-3 customBox_slide">
                    <div className="CommunSec">
                      <img
                        className="box-icon"
                        alt="Famile mm"
                        src={require("../assets/img/signup/privacy-1.png")}
                      />
                      <h2>Privacy</h2>
                    </div>

                    <p className="mt-4">
                      Your profile will not be listed publicly. Famile is built
                      as a unique network where member profiles are shared with
                      each other purely based on mutual interest only.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-10 col-md-5 col-lg-3 d-flex justify-content-center p-2">
                <div className="card">
                  <div className="card-body p-3 customBox_slide">
                    <div className="CommunSec">
                      <img
                        className="box-icon"
                        alt="Famile mm"
                        src={require("../assets/img/signup/handshake-1.png")}
                      />
                      <h2>Verified</h2>
                    </div>

                    <p className="mt-4">
                      Each profile is individually verified for genuineness and
                      intent to marry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-5 col-lg-3 d-flex justify-content-center p-2">
                <div className="card">
                  <div className="card-body p-3 customBox_slide">
                    <div className="CommunSec">
                      <img
                        className="box-icon"
                        alt="Famile mm"
                        src={require("../assets/img/signup/checked-1.png")}
                      />
                      <h2>Trust</h2>
                    </div>

                    <p className="mt-4">
                      We do not sell your data or any information regarding you
                      to third party services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex secondReel-block"
          style={{ margin: "50px 0px", overflow: "hidden" }}
        >
          <SliderProfile />
        </div>
        <div className="section position-relative third-box ">
          <div className="position-absolute" style={{ zIndex: -1 }}>
            <img
              className="bg-image"
              alt="Famile mm"
              src={require("../assets/img/signup/banner.jpg")}
            />
          </div>
          <div className="container container-width">
            <div className="col">
              <div className="d-flex justify-content-center">
                <div className="col-12 col-md-11 col-lg-7 col-xl-12 mt-3 position-relative d-flex justify-content-center">
                  <div className="inp-box">
                    <div className="rectangle rectangle4" />
                    <div className="rectangle rectangle3" />
                    <div className="rectangle rectangle2" />
                    <div className="rectangle rectangle1 pt-3">
                      <div className="d-flex justify-content-center custom_join">
                        <form
                          method="post"
                          className="signup-form mx-4"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <h2 className="text-center mt-3 mb-0 fs-4 custom_respJoin">
                            Join Our Network
                          </h2>
                          <p className="text-center mt-1 mb-4">
                            Enter your details below
                          </p>
                          <div
                            className={`inputField inputFieldName nameField ${errors.name ? "borderError" : ""
                              } ${isInputFocused ? "inputFocused" : ""}`}
                          >
                            <input
                              {...register("name", {
                                required: "Name is required",
                                minLength: {
                                  value: 2,
                                  message: "Minimum 2 characters",
                                },
                                maxLength: {
                                  value: 30,
                                  message: "Maximum 30 characters",
                                },
                                pattern: {
                                  value: /^[A-Za-z ]+$/,
                                  message: "Enter Valid Name",
                                },
                              })}
                              className={`form-control p-2 mt-1 ${errors.name
                                ? "border-danger"
                                : "border border-1"
                                }`}
                              placeholder="Name"
                              onInput={handleNameInput}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            />
                            {errors.name && (
                              <p className="errorField">
                                {errors.name.message}
                              </p>
                            )}
                          </div>
                          <div className="input-group mt-3">
                            <div className="col-2 me-2 registerInputField">
                              <select
                                className={`form-select p-2 ${errors.phone
                                  ? "border-danger"
                                  : "border border-1"
                                  } `}
                              >
                                <option>IN</option>
                              </select>
                            </div>
                            <div className="col-2 me-2 registerInputField">
                              <div className="custom_inp">
                                <input

                                  type="numeric"
                                  maxLength="10"
                                  minLength="10"
                                  value="+91"
                                  className={`form-control p-2 me-2 ${errors.phone
                                    ? "border-danger"
                                    : "border border-1"
                                    }`}
                                  placeholder="Phone"
                                  name="phone_code"
                                  disabled
                                />
                              </div>


                            </div>
                            <input
                              {...register("phone", {
                                required: "Phone Number is required",
                                minLength: {
                                  value: 10,
                                  message: "Enter Valid Phone Number",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Enter Valid Phone Number",
                                },
                                pattern: {
                                  value: /^[6789]\d{9}$/,
                                  message: "Enter Valid Phone Number",
                                },
                              })}
                              onInput={handleNumberinput}
                              className={`form-control p-2 ${errors.phone
                                ? "border-danger"
                                : "border border-1"
                                }`}
                              placeholder="Phone"
                            />
                          </div>
                          {errors.phone && (
                            <p className="errorField">{errors.phone.message}</p>
                          )}
                          <div className="d-grid gap-2 col-10 mx-auto mt-4 ">
                            <button
                              type="submit"
                              className="btn btn-primary bg-dark p-2"
                              disabled={loading}
                            >
                              JOIN
                            </button>
                          </div>
                          <div
                            className="position-relative mt-2"
                            style={{ height: "25px" }}
                          >
                            {errorText === 416 && (
                              <div className="d-flex justify-content-center">
                                <p className="error errorField text-center text-capitalize m-0">
                                  Phone Number is Already Registered
                                </p>
                                <NavLink
                                  to={"/login"}
                                  exact
                                  className="text-center text-capitalize ps-1 text-decoration-underline loginLink"
                                >
                                  Login
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                      <div className="d-flex justify-content-center pt-1">
                        <img
                          className="btm-flower signup-btm-flower"
                          alt="Mask group"
                          src={require("../assets/img/signup/mask-group.png")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section position-relative fourth-box">
          <div className="mt-1 container custom_imgBanner">
            <div className="bg_bigImg">
              <div className="bg_bigImgContent">
                <div className="bg_content">
                  <div>
                    <div className="d-flex justify-content-center custom_life">
                      <p className="sub-title ">Lifestyle & Compatibility</p>
                    </div>
                    <div className="d-flex justify-content-center custom_pae">
                      <div>
                        <p className="para custom_interest_para">
                          Famile helps you meet your life-partner who aligns
                          fully well with your goals, aspirations and lifestyle.
                        </p>
                        <p className="para custom_interest_para">
                          You can set your own preferences on your match-making
                          process and our algorithms will best serve you.
                        </p>
                      </div>
                    </div>
                    <img
                      className="flowerImg"
                      src={require("../assets/img/signup/flower.jpg")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container custom_faq	"
          style={{
            display: "flex",
            justifyContent: "space-between",

            padding: "unset",
          }}
        >
          <div className="fa_Q">
            <span className="fa_Q_H customFaq">
              {windowWidth <= 575 ? "FAQ" : "Frequently Asked Questions"}
            </span>
            <Accordion
              activeIndex={activeIndex}
              onTabChange={(e) => handleTabChange(e.index)}
              className="accordionTab"
            >
              <AccordionTab
                header={condentText(
                  "After I join Famile, how will I get matching-profiles?",
                  isTabOpen(0)
                )}
                className="accordion-collapse collapse show"
              >
                <div
                  onClick={handleTabChangeC}
                  className="accordion-body"
                  style={{ width: "90%", cursor: "pointer", color: "#141414" }}
                >
                  Every Saturday morning, at 09:00AM Indian-Time, Famile will
                  select your best matching-profiles and email you. Famile will
                  also send you an SMS notification reminding you to check the
                  email. At the same time, your profile will also be sent to 1000s of others each Saturday and they will be notified by SMS about you.
                </div>
              </AccordionTab>
              <AccordionTab
                header={condentText(
                  "How do I connect with a profile I am interested in?",
                  isTabOpen(1)
                )}
                className="accordion-collapse collapse show"
              >
                <div
                  onClick={handleTabChangeC}
                  className="accordion-body"
                  style={{ width: "90%", cursor: "pointer", color: "#141414" }}
                >
                  In the email you get with matching-profiles, there will be an
                  Express Interest button for each matching-profile. If you
                  Express Interest, the other person will be notified and if
                  they are also interested, Famile will share their phone number
                  with you by SMS, immediately.
                </div>
              </AccordionTab>
              <AccordionTab
                header={condentText("How safe is Famile?", isTabOpen(2))}
                className="accordion-collapse collapse show"
              >
                <div
                  onClick={handleTabChangeC}
                  className="accordion-body"
                  style={{ width: "90%", cursor: "pointer", color: "#141414" }}
                >
                  Safe. Very safe. We focus on providing a high-quality service.
                  Your contact details will be shared with any individual only
                  after your explicit interest in that particular person. We
                  expect all users to be respectful to each others boundaries.
                  Any user misusing our platform or abusing other users will be
                  immediately delisted. Only people who are serious about
                  finding a life-partner for marriage are allowed on Famile.
                </div>
              </AccordionTab>
              <AccordionTab
                header={condentText(
                  "Does Famile verify the profiles?",
                  isTabOpen(3)
                )}
                className="accordion-collapse collapse show"
              >
                <div
                  onClick={handleTabChangeC}
                  className="accordion-body"
                  style={{ width: "90%", cursor: "pointer", color: "#141414" }}
                >
                  We conduct basic verifications about the members who join
                  Famile. However, you are requested to conduct thorough
                  background check on a potential match you are interested in.
                </div>
              </AccordionTab>
              <AccordionTab
                header={condentText(
                  "Will my profile be publicly visible?",
                  isTabOpen(4)
                )}
                className="accordion-collapse collapse show"
              >
                <div
                  onClick={handleTabChangeC}
                  className="accordion-body"
                  style={{ width: "90%", cursor: "pointer", color: "#141414" }}
                >
                  No. Your profile, email, phone number, photo or any other
                  details about you will not be publicly visible.
                </div>
              </AccordionTab>
              <AccordionTab
                header={condentText(
                  "I received an email from Famile saying someone is interested in me. What do I do?",
                  isTabOpen(5)
                )}
                className="accordion-collapse collapse show"
              >
                <div
                  onClick={handleTabChangeC}
                  className="accordion-body"
                  style={{ width: "90%", cursor: "pointer", color: "#141414" }}
                >
                  This email means that your profile was sent to someone and
                  they are interested in you. You may receive this email anytime
                  (not just saturday). For example, someone to whom your profile
                  was sent on Saturday, may Express Interest on Monday and you
                  will get that notification immediately.
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
        <div className="section row d-flex justify-content-between ">
          <div className="container  container-widthCustom">
            <div className="col-xl-12 col-lg-12  col-sm-12 col-xs-12 p-0 d-flex customFlxboxCitizen">
              <div className="first-block-right_ct col-xl-6  col-lg-6 col-md-9 col-sm-12 custom_firstBlock_down">
                <div className="col custom_grid_cus">
                  <div>
                    <div className="d-flex justify-content-center">
                      <div className="col-5 d-flex justify-content-center ">
                        <img
                          className="img1 img-fluid  customImgHeight1Citizen"
                          alt="Famile mm"
                          src={require("../assets/img/signup/33_4_11zon.jpg")}
                        />
                      </div>
                      <div className="col-7 d-flex justify-content-center ">
                        <img
                          className="img4 img-h-full img-fluid  customImgHeight2Citizen"
                          alt="Famile mm"
                          src={require("../assets/img/signup/44_5_11zon.jpg")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex justify-content-center">
                      <div className="col-7 d-flex justify-content-center">
                        <img
                          className="img2  img-fluid  pt-0 customImgHeight3Citizen"
                          alt="Famile mm"
                          src={require("../assets/img/signup/11_2_11zon.jpg")}
                        />
                      </div>
                      <div className="col-5">
                        <img
                          className="img3 img-fluid  pt-0 customImgHeight4Citizen"
                          alt="Hands indian bride"
                          src={require("../assets/img/signup/66_7_11zon.jpg")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5  col-lg-5 col-sm-12 citizenText">
                <h2 className="text-center text-h2-seniorCitizen citizen_head">
                  Senior Citizen Welfare
                </h2>
                <p className="text-p-seniorCitizen citizen_para">
                  The Famile Network ardently champions Senior Citizen Welfare.
                </p>
                <p className="text-p-seniorCitizen citizen_para">
                  Our outreach programs extend a nurturing hand to our esteemed
                  elders, providing essential medical care and assisting with
                  their holistic well-being with essentials.
                </p>
                <p className="text-p-seniorCitizen citizen_para">
                  Additionally, for all patrons registered on Famile.org, our
                  Community Service team provides both moral and legal support
                  in resolving situations where Senior Citizens are abused.
                </p>
                <p className="text-p-seniorCitizen citizen_para">
                  Our Senior Citizen Welfare efforts have touched the lives of
                  18,000+ Senior Citizens and their families
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block mt-5">
        <Footer />
      </div>
    </>
  );
}
