import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/common.css";
import { paymentInitiate } from "../constant/url";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { GoDotFill } from "react-icons/go";

export const SignupTermsOfUse = (props) => {
  const propData = useLocation()["state"];
  let navigate = useNavigate();
  let access = sessionStorage.getItem("access_detals");
  let phone = propData?.phone;

  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    setLoading(true); // Indicate loading state

    try {
      // Create FormData and append necessary fields
      const bodyFormData = new FormData();
      bodyFormData.append("authId", access);
      bodyFormData.append("phoneNumber", phone);

      // Send POST request to initiate payment
      const response = await axios({
        method: "post",
        url: paymentInitiate,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Check response status and handle accordingly
      const res_data = response.data;
      if (res_data.status_code === 101) {
        setErrorMessage("Authentication failed. Please log in again.");
        console.log("API Authentication failed. Login again.");
      } else if (res_data.status_code === 200) {
        // If successful, redirect to the payment gateway
        const redirectUrl = new URL(
          res_data.pg_response.data.instrumentResponse.redirectInfo.url
        );

        window.location.href = redirectUrl.toString(); // Redirect to the payment gateway
      } else {
        setErrorMessage(res_data.status_msg); // Handle other status codes
        console.log(res_data.status_msg);
      }
    } catch (error) {
      // Handle errors and provide meaningful feedback
      const errorMessage =
        error.response?.data?.status_msg ||
        error.message ||
        "An error occurred during payment initiation.";
      setErrorMessage(errorMessage); // Provide a message to the user
      console.error("Payment initiation error:", errorMessage);
    } finally {
      setLoading(false); // Always set loading state to false in the end
    }
  };
  useEffect(() => {
    if (access === undefined || !access) {
      navigate("/login");
    }
  }, [access, navigate]);

  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function (event) {
        window.scrollTo(-100, 0);
        window.history.pushState(null, "", window.location.href);
      };
    };

    disableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, []);

    /*
    useEffect(() => {
      const disableBackButton = () => {
        window.history.pushState(null, document.title, window.location.href);
      };

      const handlePopstate = (event) => {
        window.scrollTo(-100, 0);
        window.history.pushState(null, document.title, window.location.href);
      };

      disableBackButton();
      window.addEventListener('popstate', handlePopstate);

      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }, []);
  */


  const termsDetails = [
    {
      value:
        "Famile.org is solely owned and operated by 1D1S Entercon Pvt Ltd, Chennai, India.",
    },
    {
      value:
        "By using our services, you agree to comply with and be bound by these terms and conditions.",
    },
    {
      value: "You must be at least 18 years old to register on our site.",
    },
    {
      value:
        "By registering, you confirm that you are legally capable of entering into a matrimonial alliance.",
    },
    {
      value:
        "Since our website specifically mentions that we do support people who do not believe in caste, by subscribing to the services of our website, you hereby confirm that you are a person who does not believe in caste, and any infringement on this ideology will be taken very seriously and your account will be banned immediately.",
    },
    {
      value:
        "Since our website specifically mentions that we do support people who do not believe in caste, by subscribing to the services of our website, you hereby confirm that you are a person who does not believe in caste, and any infringement on this ideology will be taken very seriously and your account will be banned immediately.",
    },
    {
      value:
        "Famile.org reserves the right to forthwith terminate your use and/or registration without refund of any subscription fee paid, if at any time Famile.org is, in its sole discretion, of the opinion or has any reason to believe that you are not eligible to use this Website or that you have made any misrepresentation about your eligibility.",
    },
    {
      value:
        "You agree to provide accurate and current information during the registration process.",
    },
    {
      value:
        "Profiles found to contain false information may be subject to removal without notice.",
    },
    {
      value:
        "You hereby represent and warrant that you are representing yourself or the person you are creating a profile for is your nearest member of family (such as son, daughter, brother, sister, etc.,) and you are doing so with their full knowledge and consent. By creating a profile and subscribing to our services, you agree to represent only authentic data and nothing is falsified.",
    },
    {
      value:
        "As part of the registration process, you are required to setup your preferences. If you do not set your preferred matching preferences, our algorithms will take into default cognizance, the best matching criteria setup on similar profiles as yours.",
    },
    {
      value:
        "Upon registration and payment of the annual subscription fees, Famile.org will start sending you matches, which will be based on availability of matching profiles.",
    },
    {
      value:
        "After your profile registration is completed, your profile will be shared with other matching users, and you will be receiving matching profile as well. Once this process starts, we will not be able to issue refunds.",
    },
    {
      value:
        "In special cases, where users have not received any profile matches at all during the course of their annual subscription, users may contact us, and our team may consider extending the duration of the subscription, or adding a new subscription either totally free of cost or at a reduced price. However, Famile.org reserves the right to do so and it will be at our sole discretion.",
    },
    {
      value:
        "We prioritize the privacy and security of your personal information. Please refer to our Privacy Policy for details.",
    },
    {
      value:
        "You are responsible for maintaining the confidentiality of your account credentials.",
    },
    {
      value: "Users must conduct themselves in a respectful and lawful manner.",
    },
    {
      value:
        "Harassment, abuse, or any form of misconduct towards other users is strictly prohibited.",
    },
    {
      value:
        "Users are responsible for the content they post on the site, including text, images, and other media.",
    },
    {
      value:
        "Prohibited content includes but is not limited to offensive, misleading, or illegal material.",
    },
    {
      value:
        "Famile.org is not responsible for the outcome of interactions between users.",
    },
    {
      value:
        "Users are encouraged to exercise caution and conduct thorough verification before proceeding with matrimonial alliances.",
    },
    {
      value:
        "You further understand and acknowledge that while interacting with other users, you may be exposed to content that is inaccurate, offensive, indecent, or objectionable, and you hereby waive any legal or equitable rights or remedies you have or may have against Famile.org with respect thereto and agree to indemnify and hold the Famile.org harmless to the fullest extent allowed by law regarding all matters related to your use of the services provided on Famile.org or any of our subsidiary or related service associated with us.",
    },
    {
      value:
        "Users who find suitable profiles of partners through the Website must independently verify/confirm details of the potential partners and should conduct due diligence on their own. Famile.org will not be responsible for the authenticity or correctness of any information exchanged through the Website.",
    },
    {
      value:
        "By using our services, you agree to behave responsibly while interacting with other users and will not exhibit indecent behaviour or solicit other users for financial favours, physical relationships,  harass other users in any form or engage in inappropriate communication language. You hereby understand that violation of this policy will result in immediate termination of your account and you will not be issued any refund of your subscription fees.",
    },
    {
      value:
        "You agree not to stalk or persist communication with a person may have communicated with you through Famile.org but has lost interest in you after  communications. Such behaviour will be deemed as violation of privacy and harassment and your profile will be terminated and no refunds will be made. We  will cooperate with law-enforcement authorities  depending on the seriousness and gravity of your  violation.",
    },
    {
      value:
        "You agree not to publish or transmit any data related to a profile which was communicated to you via Famile.org, to anyone else.",
    },
    {
      value:
        "Famile.org reserves the right to suspend or terminate accounts that violate these terms.",
    },
    {
      value:
        "Users may terminate their accounts at any time after notifying us.",
    },
    {
      value:
        "Users who are getting married are required to notify us so our website algorithms stop connecting users with your profile.",
    },
    {
      value:
        "Use or launch any automated system, including without limitation, robots, spiders, offline readers, etc., that accesses the Website Famile.org in a manner that sends more request messages to  Website servers in a given period of time than a human can reasonably produce in the same period by using a conventional online web browser shall result in termination of your account.",
    },
    {
      value:
        "You agree not to collect or harvest any personally identifiable information, including account names, from Famile.org, nor to use the communication systems provided by the Website for any commercial solicitation purposes.",
    },
    {
      value:
        "These terms may be modified at any time without prior notice. Users are encouraged to review the terms regularly.",
    },
    {
      value:
        "These terms are governed by the laws of Chennai, Tamil Nadu, India.",
    },
    {
      value:
        "Any disputes arising out of or relating to these terms will be subject to the exclusive jurisdiction of the courts in Chennai, Tamil Nadu, India.",
    },
    {
      value:
        "Under no circumstances will Famile.org be liable in any way for any Content, including, but  not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content made available through the Website or as a result thereof.",
    },
    {
      value:
        "You understand and agree that when using the Website, you will be exposed to Content from different sources and that Famile.org shall not be responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such Content.",
    },
    {
      value:
        "If any content on the Website does not adhere to these terms and conditions, please send an email to hello@famile.org indicating the non-adherence so as to enable Famile.org to take appropriate action as deemed necessary by Famile.org.",
    },
    {
      value:
        "The user has to inform Famile.org that the User has married another User of the Website and wishes to update the Website with a success story. Famile.org reserves the right to request such documents as proof of a marriage as it may deem fit from the User or both the Users.",
    },
    {
      value:
        "Our matching or match-making algorithms are engineered to send you up to 3 of the best matching profiles based on your preferences and your profile information.",
    },
    {
      value:
        "Our matching algorithms do not guarantee sending you matches every week, and will be able to send you profiles only based on availability of matching profiles.",
    },
    {
      value:
        "Famile.org also reserves the right to publish the success story of users at its discretion.",
    },
    {
      value:
        "Reverse engineer, decompile, disassemble, copy, reproduce, distribute, modify, transmit, perform, publish or create derivative works from or in any  way exploit any part of the Website in violation of these Terms or the laws of any country.",
    },
    {
      value:
        "Create a database in electronic or structured manual form by systematically downloading and storing the entire Website or part thereof except such information as relates or concerns you  directly.",
    },
    {
      value:
        "You are requested to keep your login credentials private and not to disclose it to anyone. Famile.org is not responsible for any misuse of your account that may arise out of you disclosing your account information to anyone else.",
    },
    {
      value: "Keep your information appropriate and updated at all times.",
    },
    {
      value:
        "Famile.org is not responsible for any issues or conflicts that may arise out of your misrepresentation of your data.",
    },
  ];

  return (
    <>
      <Header loginStatus={props.loginStatus} showLogout="showLogout" />
      <div className="bg-container">
        <div className="container d-flex justify-content-center commonContainer commonContainerTerms">
          <div className="main-div have-note">
            <div className="note d-flex justify-content-center align-items-center noteText">
              Note: Your profile can be edited later
            </div>
            <div className="row">
              <div className="col ">
                <div className="col d-flex gap-4 justify-content-center align-items-center mb-65 uploadHead">
                  <div class="profile">
                    <div class="outer">
                      <div class="inner">
                        <div id="number">75%</div>
                      </div>
                    </div>
                    <svg width="70px" height="70px">
                      <circle
                        className="profile75"
                        cx="35"
                        cy="35"
                        r="30"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div className="imageUploadHeaderHead">
                    <h2 className="imageUploadHeader ">Terms of Use</h2>
                    <p className="mb-0 imageUploadSubHeader">
                      Please read carefully before accessing our <br /> services
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    // className="mt-3 pt-3 px-sm-3 px-md-5 pb-4 sintos-content mx-149 termsOf_U"
                    className="col-xl-9 col-sm-10 py-30 onlyMobWidth sintos-content pay_gap mx-149 termsOf_U"
                    style={{ padding: "0 15px", margin: "auto" }}
                  >
                    {termsDetails.map((data, index) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          paddingTop: "15px",
                        }}
                      >
                        <div>
                          <GoDotFill style={{ width: "10px" }} />
                        </div>
                        <span>{data?.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col d-flex justify-content-center">
                  <div className="col-xl-9 col-sm-10 bg-sandal py-30 onlyMobWidth">
                    <h3 className="text-center mt-4">Rs. 2995 /-</h3>
                    <p className="text-center mt-0 mb-12 text-12 text-10">
                      For an Annual Subscription (365 Days)
                    </p>
                    <div className="d-grid gap-2 col-xl-3 col-lg-4 col-md-5 col-10 customFle">
                      <button
                        type="submit"
                        className="btn btn-primary bg-dark p-2 customSub"
                        disabled={loading}
                        onClick={() => handlePayment()}
                      >
                        {loading ? "Loading..." : "AGREE & SUBSCRIBE"}
                      </button>
                      {errorMessage && <p>{errorMessage}</p>}
                    </div>
                    <div className="Payment">
                      <img
                        src={require("../assets/img/payment.png")}
                        alt="payment icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col">
                <div className="col d-flex justify-content-start">
                  <img
                    className="img-fluid btm-flower-left"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img
                    className="img-fluid btm-flower-right"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block termsOfUseFooter">
        <Footer />
      </div>
    </>
  );
};
