import React from "react";
import "../css/sliderProfile.css";

const SliderProfile = () => {
  const userProfiles = [
    { image: require("../assets/img/signup/21_8_11zon.jpg") },
    { image: require("../assets/img/signup/22_9_11zon.jpg") },
    { image: require("../assets/img/signup/33_10_11zon.jpg") },
    { image: require("../assets/img/signup/44_11_11zon.jpg") },
    { image: require("../assets/img/signup/55_12_11zon.jpg") },
    { image: require("../assets/img/signup/66_13_11zon.jpg") },
    { image: require("../assets/img/signup/77_14_11zon.jpg") },
    { image: require("../assets/img/signup/21_8_11zon.jpg") },
    { image: require("../assets/img/signup/22_9_11zon.jpg") },
    { image: require("../assets/img/signup/33_10_11zon.jpg") },
    { image: require("../assets/img/signup/44_11_11zon.jpg") },
    { image: require("../assets/img/signup/55_12_11zon.jpg") },
    { image: require("../assets/img/signup/66_13_11zon.jpg") },
    { image: require("../assets/img/signup/77_14_11zon.jpg") },
  ];

  return (
    <div class="slider">
      <div class="slide-track">
        {userProfiles.map((pic) => (
          <div class="slidePrf">
            <img src={pic?.image} alt="" style={{ borderRadius: "20px" }} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default SliderProfile;
