import React, { useEffect, useState } from "react";
// import "../css/common.css";
import "../css/profile2.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { matchProfConf, matchProfView } from "../constant/url";

export const ProfileStep2 = (props) => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [visitedCount, setVisitedCount] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const bodyFormData = new FormData();
      bodyFormData.append("verify_match_id", id);
      axios({
        method: "post",
        url: matchProfView,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 200) {
            const profData = res_data.match_prof;
            
            if (!profData.blur_photo.startsWith("https://famile.org/be/upload/blur_img/")) {
              profData.blur_photo = `https://famile.org/be/upload/blur_img/${profData.blur_photo}`
            }
            if (!profData.photo.startsWith("https://famile.org/be/upload/customer_img/")) {
              profData.photo = `https://famile.org/be/upload/customer_img/${profData.photo}`
            }
            setProfile(profData);
            setVisitedCount(+res_data.sender_visited);
            if (res_data.sender_accepted === "1") {
              setConfirmStatus("confirmed");
            }
          }
          // NOTE: Uncomment only for testing
          // setVisitedCount(1);
          // setProfile({
          //   id: "55",
          //   user_id: "76",
          //   photo: require("../assets/img/signup/preview.png"),
          //   fname: "Kiran",
          //   email: "surendharbala0jk002@gmail.com",
          //   gender: "Male",
          //   dob: "1992-11-22",
          //   language: "12",
          //   religion: "3",
          //   edu_qual: "Bachelor Degree of Commerce",
          //   profession: "Senior Executive Accountant",
          //   annual_income: "3",
          //   food: "Vegetarian",
          //   height: "163 Cm",
          //   weight: "79 Kg",
          //   smoke: "Never",
          //   drink: "Never",
          //   maritalSts: "Never Married",
          //   city: "Tirukoilur",
          //   state: "15",
          //   country: "India",
          //   create_dt: "2024-05-20 16:10:11",
          //   relgs_name: "Sikhism",
          //   stat_name: "Jharkhand",
          //   lang_name: "Marathi",
          //   incom_name: "Rs.2 Lakhs - Rs.5 Lakhs",
          //   age: 31,
          // });
          // NOTE: Uncomment only for testing
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }, [id]);

  if (!profile) {
    return <p>Loading...</p>;
  }

  if (visitedCount > 2 && confirmStatus !== "confirmed") {
    return <p>You don't have enough view permission</p>;
  }

  return (
    <>
      <Header loginStatus={props.loginStatus} />
      <div className="customProfileBg2">
        <div className="container d-flex justify-content-center customBro">
          <div className="main-div custom_Profile1">
            <h1>Matching Profile</h1>
            <div className="content-div custom_bg">
              <div className="row customBox">
                <div className="col d-flex">
                  <div className="custom_Profile">
                    <div className="custom_textSection">
                      <div className="Box_preview">
                        <div className="previewImage">
                          <img src={confirmStatus === "confirmed" ? profile.blur_photo : profile.photo} alt="profile" />
                        </div>
                        <div className="preview_text">
                          <p>{profile.fname}</p>
                          <p>
                            {profile.age}, {profile.gender}
                          </p>
                          <p>{profile.language}</p>
                          <p>
                            {profile.city}, {profile.state}
                          </p>
                         
                          <p>{profile.profession}</p>
                          <p>{profile.incom_name}</p>
                          <p>
                            {profile.weight}, {profile.height}
                          </p>
                          <p>
                            {profile.food}, {profile.relgs_name}
                          </p>
                          <p>Smoking ({profile.smoke})</p>
                          <p>Drinking ({profile.drink})</p>
                        </div>
                      </div>

                      {/* Matching Profile */}
                      {confirmStatus !== "confirmed" ? (
                        <div>
                          <div className="preview_descp">
                            By clicking below, you are consenting to your
                            profile being sent to this person. If they are
                            mutually interested in you, you will receive access
                            to their phone number and vice versa.
                          </div>
                          <div className="agreeBtn">
                            <button disabled={confirmStatus === "loading"} onClick={() => {
                                setConfirmStatus("loading");
                                const bodyFormData = new FormData();
                                bodyFormData.append("verify_match_id", id);
                                axios({
                                  method: "post",
                                  url: matchProfConf,
                                  data: bodyFormData,
                                  headers: { "Content-Type": "multipart/form-data" },
                                })
                                  .then(function (response) {
                                    //handle success
                                    const res_data = response.data;
                                    if (res_data.status_code === 200) {
                                      setConfirmStatus("confirmed");
                                    }
                                    // NOTE: Uncomment code for testing
                                    setConfirmStatus("confirmed");
                                    // NOTE: Uncomment code for testing
                                  }).catch(() => {
                                    console.log("Error occurred");
                                    setConfirmStatus(null);
                                  })
                            }}>{confirmStatus === "loading" ? "Loading...": "EXPRESS MY INTEREST"}</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="tickMark">
                            <img
                              src={require("../assets/img/signup/tickCircle.png")}
                              alt="tick"
                            />
                          </div>
                          <p className="successText">
                            Your interest has been sent to this person. Please
                            wait while they confirm their interest.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="col d-flex justify-content-start ">
                  <img
                    className="img-fluid btm-flower-left"
                    width="160px"
                    height="160px"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img
                    className="img-fluid btm-flower-right"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ft-footer-block">
        <Footer />
      </div>
    </>
  );
};
