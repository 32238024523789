/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  resendVerificationNumber,
  verifyVerificationNumber,
  updateGeneric,
  getSubscription,
  getpersonalinfo,
} from "../constant/url";

import "../css/common.css";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { SubscriptionProgress } from "../component/SubscriptionProgress";

function VerifyPhoneBox({
  phone,
  handleResendOtp,
  handleVerifyOTP,
  setIsNumChanged,
  isClickHereClicked,
  timer,
  errorMessage,
  clearError,
}) {
  const [vCode, setvCode] = useState();
  document.addEventListener("DOMContentLoaded", function () {
    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 10;
    const ALERT_THRESHOLD = 5;

    const COLOR_CODES = {
      info: {
        color: "green",
      },
      warning: {
        color: "orange",
        threshold: WARNING_THRESHOLD,
      },
      alert: {
        color: "red",
        threshold: ALERT_THRESHOLD,
      },
    };

    const TIME_LIMIT = 20;
    let timePassed = 0;
    let timeLeft = TIME_LIMIT;
    let timerInterval = null;
    let remainingPathColor = COLOR_CODES.info.color;

    document.getElementById("app").innerHTML = `
    <div class="base-timer">
      <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g class="base-timer__circle">
          <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
          <path
            id="base-timer-path-remaining"
            stroke-dasharray="283"
            class="base-timer__path-remaining ${remainingPathColor}"
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
          ></path>
        </g>
      </svg>
      <span id="base-timer-label" class="base-timer__label">${formatTime(
        timeLeft
      )}</span>
    </div>
    `;

    startTimer();

    function onTimesUp() {
      clearInterval(timerInterval);
    }

    function startTimer() {
      timerInterval = setInterval(() => {
        timePassed = timePassed += 1;
        timeLeft = TIME_LIMIT - timePassed;
        document.getElementById("base-timer-label").innerHTML =
          formatTime(timeLeft);
        setCircleDasharray();
        setRemainingPathColor(timeLeft);

        if (timeLeft === 0) {
          onTimesUp();
        }
      }, 1000);
    }

    function formatTime(time) {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    }

    function setRemainingPathColor(timeLeft) {
      const { alert, warning, info } = COLOR_CODES;
      if (timeLeft <= alert.threshold) {
        document
          .getElementById("base-timer-path-remaining")
          .classList.remove(warning.color);
        document
          .getElementById("base-timer-path-remaining")
          .classList.add(alert.color);
      } else if (timeLeft <= warning.threshold) {
        document
          .getElementById("base-timer-path-remaining")
          .classList.remove(info.color);
        document
          .getElementById("base-timer-path-remaining")
          .classList.add(warning.color);
      }
    }

    function calculateTimeFraction() {
      const rawTimeFraction = timeLeft / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    }

    function setCircleDasharray() {
      const circleDasharray = `${(
        calculateTimeFraction() * FULL_DASH_ARRAY
      ).toFixed(0)} 283`;
      document
        .getElementById("base-timer-path-remaining")
        .setAttribute("stroke-dasharray", circleDasharray);
    }
  });

  const isValidOTP = (otp) => {
    return /^\d{5}$/.test(otp);
  };

  return (
    <>
      <h3 className="text-center mt-3 semiFont otpBttVer">Verify Phone</h3>
      <p className={`cp text-center pmt-a fw-bold}`}>+91 {phone}</p>
      <div className="d-flex justify-content-center">
        <div className="col-xl-8 col-md-8 col-sm-9 col-9">
          <input
            placeholder="Enter OTP"
            className="form-control mt-2 custom-input-bg"
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              clearError();
              if (inputValue.length <= 5 && /^\d*$/.test(inputValue)) {
                setvCode(inputValue);
              }
            }}
            value={vCode}
            maxLength="5"
          />
          <p className="custom_space" style={{ height: "10px" }} />
          {errorMessage ? (
              <p
                className={`error text-start m-0 mt-1 text-wrap text-capitalize ${
                  errorMessage ? "visible" : "invisible"
                }`}
                style={{ position: "relative", top: "-15px", padding: "unset" }}
              >
                {errorMessage}
              </p>
            ) : null}
          <div className="d-grid">
            <button
              className={`btn btn-lg otpBtt otpBtn`}
              onClick={() => handleResendOtp("phone")}
              style={{ cursor: "pointer" }}
              disabled={timer < 60}
            >
              Get OTP
            </button>
          </div>
          <div className="d-grid mt-2">
            <button
              className="btn btn-dark btn-lg otpBtt"
              onClick={() => handleVerifyOTP(2, vCode, "phone")}
              style={{ cursor: "pointer" }}
              disabled={!isValidOTP(vCode)}
            >
              PROCEED
            </button>
          </div>

          <div className="d-flex justify-content-end mt-2 pb-5">
            <a
              href="javascript:void(0)"
              className="pmt-a text-start text-dark"
              onClick={() => setIsNumChanged(true)}
              style={{ cursor: "pointer", display: "none" }}
            >
              Change Number
            </a>
            {isClickHereClicked && (
              <button
                href="javascript:void(0)"
                className=" pmt-a text-end text-muted"
                onClick={() => handleResendOtp("phone")}
                disabled={timer < 60}
                style={{ cursor: "pointer", backgroundColor: "transparent", border: "none" }}
              >
                Resend  {timer < 60 && timer > 0 ? `OTP in (${timer} seconds)` : ""}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function VerifyEmailBox({
  email,
  handleSpacePrevent,
  handleResendOtp,
  handleVerifyOTP,
  setIsEmailChange,
  isEmailVerifyClicked,
  timer,
  errorMessage,
  clearError
}) {
  const [vCode, setvCode] = useState();

  return (
    <>
      <h3 className="text-center mt-3 semiFont otpBttVer">Verify Email</h3>
      <p className={`cp text-center pmt-a pmt-b fw-bold}`}>{email}</p>
      <div className="d-flex justify-content-center mt-2 pb-5">
        <div className="col-xl-8 col-md-8 col-sm-9 col-9">
          <input
            placeholder="Enter OTP"
            className="form-control mt-2 custom-input-bg"
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              clearError();
              if (inputValue.length <= 5 && /^\d*$/.test(inputValue)) {
                setvCode(inputValue);
              }
            }}
            value={vCode}
            onKeyDown={handleSpacePrevent}
            maxLength="5"
          />
          <p className="custom_space" style={{ height: "10px" }} />
          {errorMessage ? (
              <p
                className={`error text-start m-0 mt-1 text-wrap text-capitalize ${
                  errorMessage ? "visible" : "invisible"
                }`}
                style={{ position: "relative", top: "-15px", padding: "unset" }}
              >
                {errorMessage}
              </p>
            ) : null}
          {/* {error ? (
              <p
                className={`error text-start m-0 mt-1 text-wrap text-capitalize ${
                  error ? "visible" : "invisible"
                }
            ${
              resultMessage ===
              "Your Email Has Updated, Please Click Here To Get OTP"
                ? "successMsg"
                : ""
            }`}
                style={{ position: "relative", top: "-15px", padding: "unset" }}
              >
                {resultMessage}
                {!error && !resultMessage && "Error"}
              </p>
            ) : null} */}
          <div className="d-grid ">
            {/* <button
              className={`btn btn-lg otpBtt otpBtn ${
                isEmailVerifyClicked ? "disabledBtn" : ""
              }`}
              disabled={isEmailVerifyClicked}
              onClick={() => handleResendOtp("email")}
            >
              Get OTP
            </button> */}
            <button
              className={`btn btn-lg otpBtt otpBtn`}
              onClick={() => handleResendOtp("email")}
              style={{ cursor: "pointer" }}
              // disabled={timer < 60}
            >
              Get OTP
            </button>
          </div>
          <div className="d-grid mt-2">
            <button
              className="btn otpBtt btn-dark btn-lg"
              onClick={() => handleVerifyOTP(3, vCode, "email")}
              style={{ cursor: "pointer" }}
            >
              PROCEED
            </button>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <a
              href="javascript:void(0)"
              className="pmt-a text-start text-dark"
              onClick={() => setIsEmailChange(true)}
              style={{ cursor: "pointer", display: "none"}}
            >
              Change Email
            </a>
            {/*<p className="text-center text-dark ">(50 sec)&nbsp;&nbsp;</p>*/}
            {isEmailVerifyClicked && (
              <button
                href="javascript:void(0)"
                className=" pmt-a text-end text-muted "
                onClick={() => handleResendOtp("email")}
                disabled={timer < 60}
                style={{ cursor: "pointer", backgroundColor: "transparent", border: "none" }}
              >
              Resend  {timer < 60 && timer > 0 ? `OTP in (${timer} seconds)` : ""}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export const PaymentResult = (props) => {
  const propData = useLocation()["state"];
  const access = sessionStorage.getItem("access_detals");
  const [phone, setPhone] = useState(
    propData?.phone || sessionStorage.getItem("phone")
  );
  const key =
    propData?.key === "phone-verify"
      ? 1
      : propData?.key === "email-verfiy"
      ? 2
      : 1;

  let navigate = useNavigate();
  const [vState, setvState] = useState(key);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [validTill, setValidTill] = React.useState();
  const [isClickHereClicked, setIsClickHereClicked] = useState(false);
  const [isEmailVerifyClicked, setIsEmailVerifyClicked] = useState(false);
  const [isNumChanged, setIsNumChanged] = useState(false);
  const [vphone, setvPhone] = useState(phone);
  const [email, setEmail] = useState("");
  const [isPhoneUpdated, setIsPhoneUpdated] = useState(false);
  const [isEmailUpdated, setIsEmailUpdated] = useState(false);
  const [isEmailChange, setIsEmailChange] = useState(false);
  const [vemail, setvemail] = useState("");
  const handleSpacePrevent = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };
  const handleGetSubDetails = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("authId", access);

    await axios({
      method: "post",
      url: getSubscription,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const res_data = response.data;
        if (res_data.status_code === 101) {
          // navigate("/login");
        } else if (res_data.status_code === 200) {
          setValidTill(moment(res_data.data.valid_till).format("MMM Do YYYY"));
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  React.useEffect(() => {
    handleGetSubDetails(access);
  }, [access]);

  const handleChangeFormData_verify = (e) => {
    const { name, value } = e.target;
    if (/^[0-9]*$/.test(value) && value.length <= 10) {
      setvPhone(value);
    }
  };

  const VerifyPreferanceBox = () => {
    return (
      <>
        <div className="d-flex justify-content-center mt-33 mb-3">
          <img
            src={require("../assets/img/signup/wedding_2.png")}
            alt="wedding"
          />
        </div>
        <h2 className="text-center fs-22 mx-auto otpBttVer">Verified</h2>
        <p className="text-center fs-14 col-9 col-sm-8 col-md-8 mx-auto mb-0 mt-1 otpBtt">
          Set your partner preferences by clicking the button below. Keep your
          preferences reasonably broad to attract a wide range of partner
          profiles.
        </p>
        <div className="d-flex justify-content-center pb-5">
          <div className="col-xl-8 col-md-8 col-sm-9 col-9">
            <div className="d-grid gap-2">
              <button
                className="btn btn-dark btn-lg mt-3 otpBtt"
                style={{ fontSize: 15 }}
                onClick={() => {
                  navigate("/preference", {
                    state: {
                      ...propData,
                      key: "preference",
                    },
                  });
                }}
              >
                SET PREFERENCES
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleVerifyOTP = async (statusLevel, vCode, type) => {
    setLoading(true);
    setIsNumChanged(false);
    setIsEmailChange(false);
    var bodyFormData = new FormData();
    bodyFormData.append("access_code", access);
    bodyFormData.append("verification_code", vCode);
    bodyFormData.append("type", type);

    // Just for testing otp
    // if (statusLevel === 2) {
    //   setvState(statusLevel);
    //   setSuccess(true)
    //   setError(false);
    //   setResultMessage("TempMessage");
    //   return;
    // }
    // if (statusLevel === 3) {
    //   setvState(statusLevel);
    //   setSuccess(true);
    //   setError(false);
    //   setResultMessage("TempMessage");
    //   return;
    // }
    // Just for testing otp
    await axios({
      method: "post",
      url: verifyVerificationNumber,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const res_data = response.data;
        if (res_data.status_code === 101) {
          toast("Api Authentication failed. login again.");
        } else if (res_data.status_code === 200) {
          setvState(statusLevel);
          setSuccess(true);
          setError(false);
          setResultMessage(res_data.status_msg);
        } else {
          setError(true);
          setSuccess(false);
          setResultMessage(res_data.status_msg);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    setLoading(false);
  };

  const handleUpdate = async (e) => {
    setLoading(true);
    const { name, value } = e;
    if (name === "phone") {
      setIsNumChanged(false);
      setIsPhoneUpdated(true);
      setPhone(value);
    }
    if (name === "email") {
      setIsEmailChange(false);
      setIsEmailUpdated(true);
      setEmail(value);
    }
    var bodyFormData = new FormData();
    bodyFormData.append("authId", access);
    bodyFormData.append(name, value);
    await axios({
      method: "post",
      url: updateGeneric,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const res_data = response.data;
        if (res_data.status_code === 200) {
          setError(false);
          setSuccess(true);
          setResultMessage(res_data.status_msg);
          toast.success("Update successful. Click for OTP");
        } else {
          setError(true);
          setSuccess(false);
          setResultMessage(res_data.status_msg);
          toast.error("Already Exits!");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    setLoading(false);
  };

  const handleResendOtp = async (type) => {
    setIsResendClicked(true);
    const countdown = setInterval(() => {
      setTimer((currTimer) => {
        const newTimer = currTimer - 1;
        console.log("Time:", newTimer);
        if (newTimer < 0) {
          setIsResendClicked(false);
          clearInterval(countdown);
          return 60;
        }
        return newTimer;
      }); // Decrement timer
    }, 1000);
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("access_code", access);
    bodyFormData.append("type", type);

    if (type === "phone") {
      setIsClickHereClicked(true);
    } else if (type === "email") {
      setIsEmailVerifyClicked(true);
    }
    try {
      const response = await axios.post(
        resendVerificationNumber,
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const res_data = response.data;
      if (res_data.status_code === 200) {
        setError(false);
        setSuccess(true);
        setResultMessage(res_data.status_msg);
        toast.success("OTP sent! Valid for 60sec", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      } else {
        setError(true);
        setSuccess(false);
        setResultMessage(res_data.status_msg);
        toast.error("OTP failed. Please try again!");
      }
    } catch (error) {
      console.error(
        "Error resending OTP:",
        error.response || error.message || error
      );
      setError(true);
      setSuccess(false);
      // setResultMessage("Failed to resend OTP.");
    }
    setLoading(false);
  };

  let componentDisp = null;
  switch (vState) {
    case 1:
      componentDisp = (
        <VerifyPhoneBox
          handleResendOtp={handleResendOtp}
          handleVerifyOTP={handleVerifyOTP}
          isClickHereClicked={isClickHereClicked}
          phone={phone}
          setIsNumChanged={setIsNumChanged}
          timer={timer}
          errorMessage={error && resultMessage}
          clearError={() => {
            setError(false);
            setResultMessage("");
          }}
        />
      );
      break;
    case 2:
      componentDisp = (
        <VerifyEmailBox
          email={email}
          handleResendOtp={handleResendOtp}
          handleSpacePrevent={handleSpacePrevent}
          handleVerifyOTP={handleVerifyOTP}
          isEmailVerifyClicked={isEmailVerifyClicked}
          setIsEmailChange={setIsEmailChange}
          timer={timer}
          errorMessage={error && resultMessage}
          clearError={() => {
            setError(false);
            setResultMessage("");
          }}
        />
      );
      break;
    case 3:
      componentDisp = (
        <VerifyPreferanceBox error={error} resultMessage={resultMessage} />
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    if (!access && !phone) {
      navigate("/");
    }
  }, [access, phone, navigate]);

  useEffect(() => {
    if (isPhoneUpdated) {
      setIsClickHereClicked(false);
      setResultMessage("Your Phone Has Updated, Please Click Here To Get OTP");
    }
  }, [isPhoneUpdated]);

  useEffect(() => {
    if (isEmailUpdated) {
      setIsEmailVerifyClicked(false);
      setResultMessage("Your Email Has Updated, Please Click Here To Get OTP");
    }
  }, [isEmailUpdated]);

  useEffect(() => {
    const disableBackButton = (e) => {
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = function (event) {
        window.scrollTo(-100, 0);
        window.history.pushState(null, document.title, window.location.href);
      };
    };

    disableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append("authId", access);

    axios({
      method: "post",
      url: getpersonalinfo,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((resp) => {
      if (resp.data.status_code === 200) {
        setEmail(resp.data.data.email);
      }
    });
  }, [access]);

  return (
    <>
      <Header loginStatus={props.loginStatus} showLogout="showLogout" />
      <div className="bg-container">
        <div className="container d-flex justify-content-center commonContainer customBak">
          <div className="main-div">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="col-12">
                  <div className="d-flex justify-content-center gap-4 mb-26 custom_area">
                    <div class="profile">
                      <div class="outer">
                        <div class="inner">
                          <div id="number">85%</div>
                        </div>
                      </div>
                      <svg width="70px" height="70px">
                        <circle
                          className="profile85"
                          cx="35"
                          cy="35"
                          r="30"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <div className="subscriptionHead subText">
                      <h3 className="">Subscription Confirmed!</h3>
                      <p className="cp">
                        Your subscription is valid till {validTill}
                      </p>
                    </div>
                  </div>
                  <div className="paymentDivider"></div>
                  <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12 customHei">
                      {/* <div className="col d-flex justify-content-center mt-40">
                        <img
                          className="img-fulid"
                          src={require("../assets/img/signup/tickCircle.png")}
                          alt="tick circle"
                        />
                      </div>
                      <h3 className="text-center mt-4">Welcome To Famile!</h3>
                      <p className="cp  text-center mt-4">
                        Matching profiles will be emailed to you every Saturday
                        based on availability of profiles.
                      </p> */}
                      <div className="d-flex justify-content-center zIndex-1 customTogg">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body pt-5">
                              <SubscriptionProgress status={vState} />
                              {componentDisp}
                              {/* {success && (
                                <p className="success mt-1 text-wrap text-center text-capitalize ">
                                  {resultMessage}
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`d-flex justify-content-center card p-4 p-md-5 position-relative change-num ${
                          isNumChanged ? "change-num-active" : ""
                        }`}
                      >
                        <div className="col-8 mx-auto">
                          <div className="input-group mb-3">
                            <input
                              type="number"
                              maxLength="10"
                              minLength="10"
                              className="form-control custom-input-bg p-2"
                              placeholder="Enter New Phone Number"
                              name="phone"
                              value={vphone}
                              onChange={(e) => handleChangeFormData_verify(e)}
                              onKeyDown={handleSpacePrevent}
                            />
                          </div>
                          <div className="input-group-append d-flex justify-content-end">
                            <button
                              className="btn otpBttUp closeBtn btn-dark"
                              type="button"
                              onClick={() => setIsNumChanged(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn  otpBttUp updateBtn btn-dark"
                              type="button"
                              onClick={() =>
                                handleUpdate({ name: "phone", value: vphone })
                              }
                              disabled={!vphone}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`d-flex justify-content-center card p-4 p-md-5 position-relative change-email ${
                          isEmailChange ? "change-email-active" : ""
                        }`}
                      >
                        <div className="col-9 mx-auto">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control custom-input-bg p-2"
                              placeholder="Enter New Email Id"
                              name="email"
                              value={vemail}
                              onChange={(e) => setvemail(e.target.value)}
                              maxLength="50"
                            />
                          </div>
                          <div className="input-group-append d-flex justify-content-end">
                            <button
                              className="btn otpBttUp closeBtn btn-dark"
                              type="button"
                              onClick={() => setIsEmailChange(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn updateBtn btn-dark"
                              type="button"
                              onClick={() =>
                                handleUpdate({ name: "email", value: vemail })
                              }
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col">
                <div className="col d-flex justify-content-start">
                  <img
                    className="img-fluid btm-flower-left"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img
                    className="img-fluid btm-flower-right"
                    src={require("../assets/img/signup/btmflower2.png")}
                    alt="profileimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block paymentResultFooter">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
